/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import Autocomplete from "react-autocomplete";
import { renderItem, menuStyles } from "./autocomplete_utils";

export default class OrganizationAutocompleteField extends React.Component {
  /*
   An autoselect field with a set list of options
   Props:
   {
     id<string>                     object id to be filled in the hidden input if preselected
     value<string>                  value to be filled in the hidden input if preselected
     name*<string>                  name assigned to the hidden input
     writeInName*<string>           name assigned to the hidden input for the write in option
     items*<array>                  collection of items for presentation
     placeholder<string>            placeholder text for the shown input
     label<string>                  label text for the shown input
     onParentChange<callback>       callback from parent that executes on value change
     onNewItemClick<callback>       callback from parent that executes on clicking the link to add a new item
     onWriteInFieldChange<callback> callback from parent that executes on value of write in field change
   }
  */

  constructor(props) {
    super(props);
    this.state = {
      id: props.id || "",
      value: props.value || "",
      items: props.items,
      writeInText: "",
      showWriteInOption: false,
      showWriteInField: false,
      showMenu: false
    };
  }

  handleChange(e) {
    const { value } = e.target;
    const showWriteInOption = value.trim().length > 0;
    const phrase = `Add new ${this.props.placeholder.toLowerCase()}`;

    this.setState({
      value,
      writeInText: `${phrase} '${value}'`,
      showWriteInOption,
      showMenu: true
    });
  }

  _handleNewItem() {
    this.setState({
      id: "",
      showWriteInField: true,
      showMenu: false
    });

    if (this.props.onNewItemClick) {
      this.props.onNewItemClick(this.state.value);
    }
  }

  handleSelect(_, id, item) {
    this.setState({
      id,
      value: item.value,
      showMenu: false
    });

    if (this.props.onParentChange) {
      this.props.onParentChange(id);
    }
  }

  _handleWriteInFieldChange(e) {
    const { value } = e.target;
    this.setState({ value });
    if (this.props.onWriteInFieldChange) {
      this.props.onWriteInFieldChange(value);
    }
  }

  _toggleMenu(e) {
    const showMenu = e.type === "focus";
    this.setState({ showMenu });
  }

  _renderAutocomplete() {
    return (
      <>
        <input type="hidden" name={this.props.name} value={this.state.id} />

        <Autocomplete
          items={this.state.items}
          shouldItemRender={(item, value) =>
            item &&
            item.value &&
            item.value.toLowerCase().indexOf((value || "").toLowerCase()) > -1
          }
          open={this.state.showMenu}
          getItemValue={item => item.id}
          renderItem={(item, highlighted) =>
            renderItem(this, item, highlighted)
          }
          renderMenu={(items, query) => this.renderMenu(this, items, query)}
          value={this.state.value}
          onChange={e => this.handleChange(e)}
          onSelect={(id, item) => this.handleSelect(this, id, item)}
          wrapperStyle={{ width: "100%" }}
          inputProps={{
            onBlur: e => this._toggleMenu(e),
            onFocus: e => this._toggleMenu(e)
          }}
          renderInput={props => (
            <>
              <input
                id="autocomplete-input"
                type="text"
                value={this.state.value}
                placeholder={this.props.placeholder}
                disabled={this.props.disabled}
                className={`${this.props.disabled ? "disabled" : ""}`}
                {...props}
              />
              <label>{this.props.label}</label>
            </>
          )}
        />
      </>
    );
  }

  _renderWriteInField() {
    return (
      <>
        <input
          type="text"
          name={this.props.writeInName}
          value={this.state.value}
          onChange={e => this._handleWriteInFieldChange(e)}
          onBlur={e => this._handleWriteInFieldChange(e)}
          placeholder={this.props.placeholder}
        />
        <label>{this.props.label}</label>
      </>
    );
  }

  renderMenu(_, items) {
    return (
      <div style={menuStyles}>
        {this.state.showWriteInOption ? (
          <a onClick={() => this._handleNewItem()}>{this.state.writeInText}</a>
        ) : null}
        {items}
      </div>
    );
  }

  render() {
    return (
      <>
        {this.state.showWriteInField
          ? this._renderWriteInField()
          : this._renderAutocomplete()}
      </>
    );
  }
}
