import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { NavLink, useLocation } from "react-router-dom";
import { GoChevronDown, GoChevronRight } from "react-icons/go";
import { ImRocket, ImMagicWand, ImMakeGroup } from "react-icons/im";

const SubMenu = styled.ul`
  animation: scaleForward 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

  a {
    font-size: 0.8em;
    font-weight: 300 !important;
    margin-left: 1.75rem;
  }

  margin-bottom: 2rem;
`;

function NavMenu({ activeMenu, setActiveMenu, name, url, subItems, icon }) {
  const [showChildren, setShowChildren] = useState(activeMenu === url);

  const handleMenuClick = () => {
    setShowChildren(!showChildren);
    setActiveMenu(url);
  };

  useEffect(() => {
    if (activeMenu !== url) {
      setShowChildren(false);
    }
  }, [activeMenu, url]);

  return (
    <>
      {subItems && (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <li
          onClick={() => handleMenuClick()}
          onKeyDown={() => handleMenuClick()}
        >
          {icon && icon()}
          <span style={{ marginLeft: ".5rem", marginRight: "auto" }}>
            {name}
          </span>
          {showChildren ? <GoChevronDown /> : <GoChevronRight />}
        </li>
      )}

      {!subItems && (
        <li>
          <NavLink to={`/${url}`} activeStyle={{ color: "dodgerblue" }}>
            {name}
          </NavLink>
        </li>
      )}

      {showChildren && (
        <SubMenu>
          {subItems.map(child => (
            <li key={child.name}>
              <NavLink
                to={`/${child.url}`}
                activeStyle={{ color: "dodgerblue" }}
              >
                {child.name}
              </NavLink>
            </li>
          ))}
        </SubMenu>
      )}
    </>
  );
}

export default function DocsNav({ markdownFiles }) {
  const navigationMenus = [
    {
      name: "Development",
      url: "development",
      icon: ImRocket,
      children: markdownFiles.map(file => ({
        name: file.name.charAt(0).toUpperCase() + file.name.slice(1),
        url: `development/${file.name}`
      }))
    },
    {
      name: "Design System",
      url: "design",
      icon: ImMagicWand,
      children: [
        {
          name: "Typography",
          url: "design/typography"
        },
        {
          name: "Color",
          url: "design/color"
        }
      ]
    },
    {
      name: "Components",
      url: "components",
      icon: ImMakeGroup,
      children: [
        {
          name: "Button",
          url: "components/button"
        },
        {
          name: "Text Input",
          url: "components/text-input"
        },
        {
          name: "Auto Suggest",
          url: "components/auto-suggest"
        },
        {
          name: "Checkbox",
          url: "components/checkbox"
        },
        {
          name: "Loader",
          url: "components/loader"
        },
        {
          name: "Select",
          url: "components/select"
        },
        {
          name: "Modal",
          url: "components/modal"
        },
        {
          name: "Alert",
          url: "components/alert"
        }
      ]
    }
  ];

  const { pathname } = useLocation();

  const [activeMenu, setActiveMenu] = useState(
    pathname.split("/")[1] ?? "development"
  );

  return (
    <nav>
      {navigationMenus.map(navItem => (
        <NavMenu
          key={navItem.url}
          activeMenu={activeMenu}
          setActiveMenu={setActiveMenu}
          name={navItem.name}
          url={navItem.url}
          subItems={navItem.children}
          icon={navItem.icon}
        />
      ))}
    </nav>
  );
}
