import React from "react";

export function renderItem(_, item, highlighted) {
  const style = {
    backgroundColor: highlighted ? "#eee" : "transparent",
    padding: ".5rem .25rem"
  };
  return (
    <div key={item.id} id={item.value} style={style}>
      {item.value}
    </div>
  );
}

export const menuStyles = {
  backgroundColor: "#fff",
  border: "1px solid lightgrey",
  position: "absolute",
  width: "420px",
  zIndex: "10",
  padding: "1rem",
  lineHeight: "1.3",
  fontSize: "16px",
  maxHeight: "16rem",
  overflowY: "scroll"
};
