import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";
import { red, grayLight, green, blue, gold } from "../../styles/colors";
import { useAuthContext } from "../Shared/ContextProviders/AuthProvider";
import PetitionProgressBar from "./PetitionProgressBar";

const Container = styled.article`
  border-radius: 4px;
  background: #fff;
  height: fit-content;
  @media only screen and (min-width: 600px) {
    /* min-width: fit-content; */
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: white;
    border-radius: 6px 6px 0 0;
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(38, 128, 240, 1) 0%,
      rgba(155, 175, 217, 1) 100%
    );
    padding: 1rem;
    h3 {
      margin: 0;
      font-weight: 500;
      font-size: 1.5em;
    }
    a {
      color: white !important;
      font-size: 1em !important;
      &:hover {
        transform: scale(1.02);
      }
    }
  }
`;

const ProgressBarContainer = styled.li`
  display: block;
  justify-content: space-between;
  list-style: none;
  &&& {
    margin: 0;
  }
  padding: 0.25rem;
  align-items: center;
  border-bottom: 1px solid ${grayLight};
`;

const IntakeItem = styled.li`
  display: block;
  justify-content: space-between;
  list-style: none;
  &&& {
    margin: 0;
  }
  padding: 0.5rem;
  align-items: center;
  border-bottom: 1px solid ${grayLight};

  &:last-child {
    border-radius: 0 0 4px 4px;
  }

  i,
  .submitted-at {
    color: ${props => (props.submitted ? green : red)};
  }

  .started-at {
    color: lightsalmon;
  }

  h3 {
    margin: 0;
    font-size: 16px;
  }

  h6 {
    font-weight: 400;
    font-size: 14px;
    font-style: italic;
  }
  a,
  button {
    justify-self: end;
  }

  .icon-and-details {
    display: flex;
    align-items: center;
    i {
      margin: 0 1rem 0 0.5rem;
    }
  }

  @media only screen and (max-width: 600px) {
    a,
    button {
      margin-top: 0.5rem;
      width: 100%;
    }
  }

  @media only screen and (min-width: 600px) {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 2fr 1fr 1fr;

    border-left: 4px solid transparent;
    &:hover {
      border-left: 4px solid ${blue};
    }

    div {
      display: block;
    }

    a {
      margin-top: 0;
    }
  }
`;

export default function PetitionIntakesBox({
  header,
  noHeader,
  progress,
  intakes,
  isCompanyIntake,
  linkToPetition,
  internal
}) {
  const { id: currentUserId } = useAuthContext();

  const renderIntakeButton = intake => {
    // always show the view button for the internal client show view
    if (internal) {
      return (
        <a href={`/intakes/${intake.id}`}>
          <button type="button" className="secondary">
            View
          </button>
        </a>
      );
    }

    if (intake.userId !== currentUserId) return <div />;

    if (intake.submittedAt) {
      return (
        <a href={`/intakes/${intake.id}`}>
          <button type="button" className="secondary">
            Edit
          </button>
        </a>
      );
    }

    if (intake.startedAt) {
      return (
        <a href={`/intakes/${intake.id}`}>
          <button type="button" className="secondary">
            Continue
          </button>
        </a>
      );
    }

    return (
      <a href={`/intakes/${intake.id}`}>
        <button type="button">Start</button>
      </a>
    );
  };

  if (!intakes) return <div />;
  return (
    <Container data-testid={header}>
      {!noHeader && (
        <header>
          <h3>{header}</h3>
          {linkToPetition && <a href={linkToPetition}>Go to petition</a>}
        </header>
      )}
      {intakes.map(intake => (
        <IntakeItem
          submitted={intake.submittedAt}
          key={intake.id}
          data-testid={intake.id}
        >
          <div className="icon-and-details">
            {!currentUserId || intake.userId === currentUserId ? (
              <i className="fas fa-circle" />
            ) : (
              <i className="fas fa-circle" style={{ color: "#FFF" }} />
            )}
            <div>
              <h3>
                {intake.intakeType === "CompanyIntake" ||
                intake.intakeType === "ExceptionalAbilityCompanyIntake"
                  ? `${intake.companyName} Information`
                  : intake.intakeLabel}
              </h3>
              <h6>{intake.userName}</h6>
            </div>
          </div>
          {intake.submittedAt ? (
            <h6 className="submitted-at">
              Submitted on {moment(intake.submittedAt).format("MM/DD/YY")}
            </h6>
          ) : intake.startedAt ? (
            <h6 className="started-at">
              Started on {moment(intake.startedAt).format("MM/DD/YY")}
            </h6>
          ) : (
            <div />
          )}
          {renderIntakeButton(intake)}
        </IntakeItem>
      ))}
    </Container>
  );
}

PetitionIntakesBox.propTypes = {
  header: PropTypes.string,
  intakes: PropTypes.array,
  isCompanyIntake: PropTypes.bool
};
