import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { CheckboxItem } from "../../Shared/CheckBox/index";
import LiveCode from "../LiveCode";

const CheckboxCode = `
        <div class="checkbox-wrapper">
          <label class="input-button-label">
            <input type="checkbox"/>
            <div class="checkbox">This is a normal checkbox.</div>
          </label>
          <label class="input-button-label">
            <input type="checkbox"/>
            <div class="checkbox">This is a normal checkbox, but with a super long label. Lots of our checkboxes have long labels. We set a width on checkboxes to make the labels more readable.</div>
          </label>
          <label class="input-button-label">
            <input type="checkbox" checked="yes"/>
            <div class="checkbox">This is what a checked checkbox looks like.</div>
          </label>
          <label class="input-button-label">
            <input type="checkbox"/>
            <div class="checkbox">A group of checkboxes get wrapped in a div called "checkbox-wrapper".</div>
          </label>
        </div>

`;

const CheckboxCode2 = `
  // input id must match label's htmlFor value so that the label triggers the checkbox
  <CheckboxItem>
    <input
      id="mycheckbox"
      type="checkbox"
      onChange={e => alert("Checkbox value changed")}
    />
    <label htmlFor="mycheckbox">
      Checkbox Value
    </label>
  </CheckboxItem>
`;

export default function CheckboxDocs(props) {
  return (
    <>
      <h4>Checkboxes allow the user to select one or more items from a set.</h4>
      <LiveCode code={CheckboxCode}>
        <p>We have some fancy checkboxes with labels that are clickable.</p>
      </LiveCode>
      <LiveCode scope={{ CheckboxItem }} code={CheckboxCode2}>
        <p>
          We also have a controllable checkbox item wrapper with an input that
          you can pass a change handler to.
        </p>
      </LiveCode>
    </>
  );
}

CheckboxDocs.propTypes = {};
