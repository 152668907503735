import React, { useMemo, useState } from "react";
import { useAlert } from "react-alert";
import Loader from "react-loader-spinner";
import Select from "react-select";
import { useQuery } from "react-query";
import { IndexRow } from "../Shared/IndexRow/IndexRow";
import { FormInput } from "../Shared/Forms/index";
import ButtonSecondary from "../Shared/ButtonSecondary";
import request from "../../services/request";

export default function ClientForm(props) {
  const popup = useAlert();
  const { setClients, setSortBy } = props;

  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    email: ""
  });

  const [organizationsSelected, setOrganizationsSelected] = useState([]);

  const handleFormChange = e => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    });
  };

  const [formVisible, setFormVisible] = useState(false);

  const {
    isLoading: organizationsLoading,
    error: organizationsError,
    data: organizationsData
  } = useQuery("organizations", () => request(Routes.organizations_path()));

  if (organizationsError) {
    popup.show(`Something went wrong: \n ${organizationsError.message}`, {
      type: "error"
    });
  }

  const petitionerItems = useMemo(
    () =>
      organizationsData?.items.map(org => ({
        value: org.id,
        label: org.to_s
      })),
    [organizationsData]
  );

  const [loading, setLoading] = useState(false);

  const createClient = async () => {
    try {
      setLoading(true);
      const { firstName, lastName, email } = formState;

      const requestBody = {
        person: {
          first_name: firstName,
          last_name: lastName,
          email,
          organization_ids: organizationsSelected.map(org => org.value)
        }
      };
      const newClientJson = await request(Routes.clients_path(), {
        body: requestBody
      });

      setClients(prev => [newClientJson, ...prev]);
      setSortBy("createdAt");

      popup.show(
        "New client added. You can trigger the welcome email with the buttons below",
        { type: "success" }
      );

      setFormState({
        firstName: "",
        lastName: "",
        email: ""
      });
      setFormVisible(false);
    } catch (error) {
      popup.show(error.errors?.toString(), { type: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleSelectChange = selectedOptions => {
    if (!selectedOptions) {
      setOrganizationsSelected([]);
    } else {
      setOrganizationsSelected(selectedOptions);
    }
  };

  return (
    <>
      {!formVisible && (
        <tr>
          <td>
            <ButtonSecondary
              text="Add new client"
              onClick={() => setFormVisible(true)}
            />
          </td>
        </tr>
      )}
      {formVisible && (
        <IndexRow>
          <td>
            <FormInput
              name="firstName"
              type="text"
              placeholder="First Name"
              value={formState.firstName}
              onChange={e => handleFormChange(e)}
            />
          </td>
          <td>
            <FormInput
              name="lastName"
              type="text"
              placeholder="Last Name"
              value={formState.lastName}
              onChange={e => handleFormChange(e)}
            />
          </td>
          <td>
            <FormInput
              name="email"
              type="text"
              placeholder="Email"
              value={formState.email}
              onChange={e => handleFormChange(e)}
            />
          </td>
          {!organizationsLoading && (
            <Select
              data-testid="select"
              value={organizationsSelected}
              options={petitionerItems}
              onChange={handleSelectChange}
              className="organizations-select"
              classNamePrefix="organizations"
              placeholder="Select Organization..."
              isClearable
              isMulti
            />
          )}
          <td
            style={{
              textAlign: "right",
              padding: 0
            }}
          >
            {loading && (
              <Loader type="ThreeDots" color="#2680FA" height={18} width={64} />
            )}
            {!loading && (
              <>
                <button
                  type="button"
                  className="small cancel u_margin-right-1rem"
                  onClick={() => setFormVisible(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="small"
                  onClick={() => createClient()}
                >
                  Save
                </button>
              </>
            )}
          </td>
        </IndexRow>
      )}
    </>
  );
}

ClientForm.propTypes = {};
