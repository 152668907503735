/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
import React from "react";
import AttributeDisplay from "./AttributeDisplay";
import AddressForm from "./AddressForm";

const buildAddress = attributes => {
  const line = attributes.find(a => a.name === "line_1").value;
  const city = attributes.find(a => a.name === "city").value;
  const state = attributes.find(a => a.name === "region").value;
  const zip = attributes.find(a => a.name === "postal_code").value;
  return `${line}, ${city} ${state} ${zip}`;
};

export default class AddressItem extends React.Component {
  // See ClientInformation for documentation

  constructor(props) {
    super(props);
    this.attribute = props.attribute;
    this.state = {
      value: props.attribute.value || "",
      showForm: !props.attribute.value,
      attributesHash: this._setAttributesHash(this.attribute.attributes)
    };
  }

  _setAttributesHash(attributes) {
    if (attributes) {
      const attributesHash = {};
      // eslint-disable-next-line array-callback-return
      attributes.map(attribute => {
        attributesHash[attribute.name] = attribute.value;
      });
      return attributesHash;
    }
  }

  _handleCancel() {
    this.setState({ showForm: false });
  }

  _handleSubmit(data) {
    this.setState({
      attributesHash: this._setAttributesHash(data),
      showForm: false,
      value: buildAddress(data)
    });
  }

  _handleValueClick(e) {
    e.preventDefault();
    this.setState({ showForm: true });
  }

  _usAddress() {
    return (
      this.attribute.name === "us_home_address" ||
      this.attribute.name === "us_mailing_address"
    );
  }

  _renderForm() {
    return (
      <AddressForm
        id={this.props.id}
        type={this.props.type}
        value={this.state.value}
        attribute={this.attribute}
        usAddress={this._usAddress()}
        attributesHash={this.state.attributesHash}
        handleCancel={() => this._handleCancel()}
        handleSubmit={hash => this._handleSubmit(hash)}
      />
    );
  }

  _renderDisplay() {
    return (
      <AttributeDisplay
        value={this.state.value}
        attribute={this.attribute}
        attributesHash={this.state.attributesHash}
        onClick={e => this._handleValueClick(e)}
      />
    );
  }

  render() {
    return (
      <div className="attribute-item attribute">
        <h6 className="label">{this.attribute.label}</h6>
        {this.state.showForm ? this._renderForm() : this._renderDisplay()}
      </div>
    );
  }
}
