import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import CheckBoxItem from "../../../Shared/CheckboxItem/CheckboxItem";
import { blue, grayDarkest, red } from "../../../../styles/colors";

const SourceHeader = styled.a`
  &&& {
    font-size: 16px;
    padding: 0;
    margin: 1rem 0;
    color: ${props => (props.selected ? blue : grayDarkest)};
    &:hover {
      color: ${props => (props.destructive ? red : blue)};
    }
  }
`;

const SectionsList = styled.div`
  border-left: 1px gray;
  margin: 0 0 1rem 1rem;
`;

export default function ClientInfoFilters(props) {
  const { filters = [], filterState = {}, dispatchFilter } = props;

  const handleSourceChange = e => {
    dispatchFilter({ type: "SET_SOURCE", source: e.target.name });
  };

  const handleSectionChange = e => {
    if (e.target.checked) {
      dispatchFilter({
        type: "SET_SECTIONS",
        sections: [...filterState.sections, e.target.name]
      });
    }
    if (!e.target.checked) {
      dispatchFilter({
        type: "SET_SECTIONS",
        sections: filterState.sections.filter(
          section => section !== e.target.name
        )
      });
    }
  };

  return (
    <div className="nav-item">
      {filters.map(filter => (
        <div key={filter.name}>
          <SourceHeader
            className="button text-button section-menu"
            selected={filterState.source === filter.name}
            name={filter.name}
            onClick={e => handleSourceChange(e)}
          >
            {filter.name}
          </SourceHeader>
          {filterState.source === filter.name && filter.sections.length > 0 && (
            <SectionsList>
              {filter.sections.map(section => (
                <li key={section.name} style={{ listStyleType: "none" }}>
                  <CheckBoxItem
                    checked={
                      filterState.sections &&
                      filterState.sections.includes(section.name)
                    }
                    name={section.name}
                    handleChange={e => handleSectionChange(e)}
                    text={section.name}
                  />
                </li>
              ))}
            </SectionsList>
          )}
        </div>
      ))}
      {filterState.source && (
        <>
          <hr />
          <SourceHeader
            destructive
            onClick={() => dispatchFilter({ type: "CLEAR_FILTERS" })}
          >
            Clear Filters
          </SourceHeader>
        </>
      )}
    </div>
  );
}

ClientInfoFilters.propTypes = {
  filters: PropTypes.array
};
