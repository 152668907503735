/* eslint-disable react/destructuring-assignment */
import React from "react";
import Form from "../../../FormFields/Form";
import AttributeInput from "../AttributeInput";
import EntityFields from "../../../FormFields/EntityFields";
import ActionBar from "../../../FormFields/ActionBar";
import FileFieldContainer from "../FileFieldContainer";

export default class AttributeForm extends React.Component {
  // See ClientInformation for documentation

  constructor(props) {
    super(props);
    this.state = {
      showActions: false,
      persistedValue: this.props.value,
      error: "",
      attribute: this.props.attribute,
      loading: false
    };
  }

  _handleSubmit(e) {
    e.preventDefault();

    const { value } = this.props;

    if (this.props.noSubmit) {
      this.props.toggleForm(!value);
      this.props.setValue(value);
    } else {
      const context = this;
      const data = new FormData(e.target);
      const url = Routes.entity_path(this.props.id);

      fetch(url, { method: "PUT", body: data }).then(response => {
        if (response.ok) {
          this.props.toggleForm(!value);
          this.props.setValue(value);
        } else {
          response.json().then(error => {
            context.setState({ error });
          });
        }
      });
    }
  }

  async _handleUpload(e) {
    e.preventDefault();

    try {
      const url = Routes.entity_path(this.props.id);

      const data = new FormData(e.target.parentElement);

      const response = await fetch(url, { method: "PUT", body: data });

      if (!response.ok) {
        this.setState({ error: response.error });
      }

      if (response.ok) {
        const json = await response.json();
        this.setState({ attribute: json });
        this.setState({ loading: false });
      }
    } catch (error) {
      this.setState({ error });
    }
  }

  _handleInputBlur() {
    if (this.props.value && this.props.value === this.state.persistedValue) {
      this.props.toggleForm(false);
    }
  }

  _handleInputChange(e) {
    // Set value either from the event.target (input) or the value passed by
    // hidden inputs used in masked fields or autocompletes
    const value = e.target ? e.target.value : e;
    // eslint-disable-next-line react/no-access-state-in-setstate
    const showActions = value !== this.state.persistedValue;

    this.props.setValue(value);
    this.setState({ showActions });
  }

  _handleCancel() {
    let showForm;

    if (this.props.attribute.inputType === "radio") {
      showForm = false;
    } else {
      showForm = !this.state.persistedValue;
    }

    this.props.toggleForm(showForm);
    this.props.setValue(this.state.persistedValue);
    this.setState({ showActions: false });
  }

  render() {
    if (
      this.props.attribute.inputType === "attachment" ||
      this.props.attribute.inputType === "attachments"
    ) {
      return (
        <Form method="PUT">
          <FileFieldContainer
            handleUpload={e => this._handleUpload(e)}
            multiple={this.props.attribute.inputType === "attachments"}
            attribute={this.state.attribute}
            loading={this.state.loading}
            setLoading={value => this.setState({ loading: value })}
          />
        </Form>
      );
    }
    return (
      <Form onSubmit={e => this._handleSubmit(e)} method="PUT">
        <EntityFields type={this.props.type} id={this.props.id} />
        <AttributeInput
          value={this.props.value}
          attribute={this.props.attribute}
          error={this.state.error}
          handleInputChange={e => this._handleInputChange(e)}
          handleInputBlur={() => this._handleInputBlur()}
          inputRef={this.props.inputRef}
        />
        {this.state.showActions && (
          <ActionBar handleCancel={() => this._handleCancel()} />
        )}
      </Form>
    );
  }
}
