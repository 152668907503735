/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import Autocomplete from "react-autocomplete";
import { menuStyles } from "./autocomplete_utils";

export default class PersonAutocompleteField extends React.Component {
  /*
   An autoselect field with a set list of options
   Props:
   {
     id<string>                     object id to be filled in the hidden input if preselected
     value<string>                  value to be filled in the hidden input if preselected
     name*<string>                  name assigned to the hidden input
     personFieldsPrefix*<string>    name used to prefix the person fields
     items*<array>                  collection of items for presentation
     placeholder<string>            placeholder text for the shown input
     label<string>                  label text for the shown input
     onParentChange<callback>       callback from parent that executes on value change
     onNewItemClick<callback>       callback from parent that executes on clicking the link to add a new item
     onPersonFieldsChange<callback> callback from parent that executes on value of person fields changes
   }
  */

  constructor(props) {
    super(props);
    this.attribute = props.attribute;
    this.state = {
      id: props.id || "",
      value: props.value || "",
      firstName: "",
      lastName: "",
      email: "",
      items: props.items,
      writeInText: "",
      showWriteInOption: false,
      showMenu: false,
      showPersonFields: false
    };
  }

  handleChange(e) {
    const { value } = e.target;
    const showWriteInOption = value.trim().length > 0;
    const phrase = `Add new ${this.props.placeholder.toLowerCase()}`;

    this.setState({
      value,
      writeInText: `${phrase} '${value}'`,
      showWriteInOption,
      showMenu: true
    });
  }

  _handleNewItem() {
    // eslint-disable-next-line react/destructuring-assignment
    // eslint-disable-next-line react/no-access-state-in-setstate
    const name = this.state.value.split(" ");
    this.setState({
      id: "",
      showMenu: false,
      firstName: name[0],
      lastName: name[1],
      showPersonFields: true
    });

    if (this.props.onNewItemClick) {
      this.props.onNewItemClick(this.state.value);
    }
  }

  _handlePersonFieldsChange(e) {
    const { value } = e.target;
    // eslint-disable-next-line default-case
    switch (e.target.placeholder) {
      case "First Name":
        this.setState({ firstName: value });
        break;
      case "Last Name":
        this.setState({ lastName: value });
        break;
      case "Email":
        this.setState({ email: value });
        break;
    }
    if (this.props.onPersonFieldsChange) {
      const name = `${this.state.firstName} ${this.state.lastName} — ${this.state.email}`;
      this.props.onPersonFieldsChange(name);
    }
  }

  handleSelect(_, id, item) {
    this.setState({
      id,
      value: item.value,
      showMenu: false
    });

    if (this.props.onParentChange) {
      this.props.onParentChange(id);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  _renderItem(_, item, highlighted) {
    const name = item.value.match(/^[^—]*/);
    const email = item.value.match(/[—^].*/);
    const style = {
      backgroundColor: highlighted ? "#eee" : "transparent",
      padding: ".5rem .25rem"
    };
    const emailStyle = { color: "#818DA4" };
    return (
      <div key={item.id} id={item.value} style={style}>
        {name}
        <span style={emailStyle}>{email}</span>
      </div>
    );
  }

  _toggleMenu(e) {
    const showMenu = e.type === "focus";
    this.setState({ showMenu });
  }

  _renderAutocomplete() {
    return (
      <React.Fragment>
        <input type="hidden" name={this.props.name} value={this.state.id} />
        <Autocomplete
          items={this.state.items}
          shouldItemRender={(item, value) =>
            item &&
            item.value &&
            item.value.toLowerCase().indexOf((value || "").toLowerCase()) > -1
          }
          open={this.state.showMenu}
          getItemValue={item => item.id}
          renderItem={(item, highlighted) =>
            this._renderItem(this, item, highlighted)
          }
          renderMenu={(items, query) => this.renderMenu(this, items, query)}
          value={this.state.value}
          onChange={e => this.handleChange(e)}
          onSelect={(id, item) => this.handleSelect(this, id, item)}
          wrapperStyle={{ width: "100%" }}
          inputProps={{
            onBlur: e => this._toggleMenu(e),
            onFocus: e => this._toggleMenu(e)
          }}
          renderInput={props => (
            <React.Fragment>
              <input
                type="text"
                placeholder={this.props.placeholder}
                {...props}
              />
              <label>{this.props.label}</label>
            </React.Fragment>
          )}
        />
      </React.Fragment>
    );
  }

  _renderPersonFields() {
    return (
      <React.Fragment>
        <div className="text-input-wrapper">
          <input
            type="text"
            name={`${this.props.personFieldsPrefix}[first_name]`}
            value={this.state.firstName}
            onChange={e => this._handlePersonFieldsChange(e)}
            onBlur={e => this._handlePersonFieldsChange(e)}
            placeholder="First Name"
          />
          <label>First Name</label>
        </div>
        <div className="text-input-wrapper">
          <input
            type="text"
            name={`${this.props.personFieldsPrefix}[last_name]`}
            value={this.state.lastName}
            onChange={e => this._handlePersonFieldsChange(e)}
            onBlur={e => this._handlePersonFieldsChange(e)}
            placeholder="Last Name"
          />
          <label>Last Name</label>
        </div>
        <div className="text-input-wrapper">
          <input
            type="text"
            name={`${this.props.personFieldsPrefix}[email]`}
            value={this.state.email}
            onChange={e => this._handlePersonFieldsChange(e)}
            onBlur={e => this._handlePersonFieldsChange(e)}
            placeholder="Email"
          />
          <label>Email</label>
        </div>
      </React.Fragment>
    );
  }

  renderMenu(_, items) {
    return (
      <div style={menuStyles}>
        {this.state.showWriteInOption ? (
          <a onClick={() => this._handleNewItem()}>{this.state.writeInText}</a>
        ) : null}
        {items}
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.state.showPersonFields
          ? this._renderPersonFields()
          : this._renderAutocomplete()}
      </React.Fragment>
    );
  }
}
