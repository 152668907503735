/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import ClientInfoSearch from "./ClientInfoSearch/ClientInfoSearch";
import ClientInfoFilters from "./ClientInfoFilters/ClientInfoFilters";

export default function ClientInformationNav(props) {
  const { filters, filterState, dispatchFilter } = props;

  return (
    <nav className="no-background">
      <div className="nav-content-wrapper" style={{ marginTop: "3rem" }}>
        <ClientInfoSearch dispatchFilter={dispatchFilter} />
        <ClientInfoFilters
          filters={filters}
          filterState={filterState}
          dispatchFilter={dispatchFilter}
        />
      </div>
    </nav>
  );
}
