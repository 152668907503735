import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Loader from "react-loader-spinner";
import { LiveEditor, LiveError, LivePreview, LiveProvider } from "react-live";
import github from "prism-react-renderer/themes/github";

const loaderCode = `
  <Loader type="ThreeDots" color="#2680FA" height={64} width={64} />;
`;

export default function LoaderDocs(props) {
  return (
    <>
      <h4>
        Loaders are used to indicate that something is happening in the
        background and that they should wait.
      </h4>

      <div>
        <LiveProvider scope={{ Loader }} code={loaderCode} theme={github}>
          <div>
            <LivePreview />
          </div>

          <LiveEditor
            style={{
              borderRadius: "4px",
              margin: "1rem 0",
              display: "grid"
            }}
          />
          <LiveError />
        </LiveProvider>
      </div>
    </>
  );
}

LoaderDocs.propTypes = {};
