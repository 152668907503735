/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
import React from "react";
import Autocomplete from "react-autocomplete";
import { renderItem, menuStyles } from "./autocomplete_utils";

export default class AutocompleteWithWriteInField extends React.Component {
  /*
   An autoselect field with a set list of options
   Props:
   {
     id<string>                object id to be filled in the hidden input if preselected
     value<string>             value to be filled in the hidden input if preselected
     name*<string>             name assigned to the hidden input
     writeInName*<string>      name assigned to the hidden input for the write in option
     items*<array>             collection of items for presentation
     placeholder<string>       placeholder text for the shown input
     label<string>             label text for the shown input
   }
  */

  constructor(props) {
    super(props);
    this.state = {
      id: props.id || "",
      value: props.value || "",
      items: props.items,
      writeInText: "",
      showWriteInOption: false,
      showMenu: false
    };
  }

  handleChange(e) {
    const { value } = e.target;
    let showWriteInOption;
    const phrase = `Add new ${this.props.placeholder.toLowerCase()}`;

    if (value.trim().length > 0) {
      showWriteInOption = true;
    } else {
      showWriteInOption = false;
    }

    this.setState({
      value,
      writeInText: `${phrase} '${value}'`,
      showWriteInOption,
      showMenu: true
    });
  }

  _handleNewItem() {
    this.setState({
      id: "",
      showMenu: false
    });
  }

  handleSelect(_, id, item) {
    this.setState({
      id,
      value: item.value,
      showMenu: false
    });
  }

  _toggleMenu(e) {
    let showMenu;
    if (e.type === "focus") {
      showMenu = true;
    } else if (e.type === "blur") {
      showMenu = false;
    }
    this.setState({
      showMenu
    });
  }

  renderMenu(_, items) {
    return (
      <div style={menuStyles}>
        {this.state.showWriteInOption ? (
          <a onClick={() => this._handleNewItem()}>{this.state.writeInText}</a>
        ) : null}
        {items}
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <input type="hidden" name={this.props.name} value={this.state.id} />

        <Autocomplete
          items={this.state.items}
          shouldItemRender={(item, value) =>
            item &&
            item.value &&
            item.value.toLowerCase().indexOf((value || "").toLowerCase()) > -1
          }
          open={this.state.showMenu}
          getItemValue={item => item.id}
          renderItem={(item, highlighted) =>
            renderItem(this, item, highlighted)
          }
          renderMenu={(items, query) => this.renderMenu(this, items, query)}
          value={this.state.value}
          onChange={e => this.handleChange(e)}
          onSelect={(id, item) => this.handleSelect(this, id, item)}
          wrapperStyle={{ width: "100%" }}
          inputProps={{
            onBlur: e => this._toggleMenu(e),
            onFocus: e => this._toggleMenu(e)
          }}
          renderInput={props => (
            <React.Fragment>
              <input
                type="text"
                name={this.props.writeInName}
                placeholder={this.props.placeholder}
                {...props}
              />
              <label>{this.props.label}</label>
            </React.Fragment>
          )}
        />
      </React.Fragment>
    );
  }
}
