/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import Logo from "images/logo_darkest.svg";
import LaptopGirl from "images/girl_on_laptop.svg";
import DesktopGirl from "images/girl_on_desktop.svg";
import styled from "styled-components";
import { useCookies } from "react-cookie";
import { FaCircle, FaRegCircle } from "react-icons/fa";
import Modal from "../Shared/Modal/Modal";

const Bullets = styled.ul`
  list-style: inside !important;
  font-size: 16px;
  margin: 1rem 0;
  li {
    list-style-position: outside;
    margin-left: 1rem;
  }
`;

const Breadcrumbs = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  margin-top: 1.5rem;

  svg {
    margin: 0.25rem;
    font-size: 0.5em;
  }
`;

export default function WelcomeFlow() {
  const [page, setPage] = useState(1);

  const [isOpen, setIsOpen] = useState(true);

  const [reverse, setReverse] = useState(false);

  const [, setCookie] = useCookies(["showWelcomeFlow"]);

  const handleNext = () => {
    setReverse(false);
    setIsOpen(false);
    setTimeout(() => {
      setIsOpen(true);
      setPage(prev => prev + 1);
    }, 500);
  };

  const handlePrevious = () => {
    setReverse(true);
    setIsOpen(false);
    setTimeout(() => {
      setIsOpen(true);
      setPage(prev => prev - 1);
    }, 500);
  };

  useEffect(() => {
    if (page === 4) {
      setCookie("showWelcomeFlow", false);
    }
  }, [page, setCookie]);

  if (page <= 3)
    return (
      <Modal show={isOpen} reverse={reverse}>
        {page === 1 && (
          <div>
            <div className="centered" style={{ marginTop: "1rem" }}>
              <img width={320} alt="legalpad" src={Logo} />
            </div>
            <div style={{ textAlign: "center" }}>
              <h4>Welcome to your Legalpad Dashboard!</h4>
              <p>
                You can share important immigration information with us here.
              </p>
            </div>
          </div>
        )}
        {page === 2 && (
          <div>
            <div className="centered" style={{ marginTop: "1rem" }}>
              <img width="auto" alt="person on laptop" src={LaptopGirl} />
            </div>
            <div>
              <h3>Step 1</h3>
              <strong style={{ fontSize: "18px" }}>
                Fill and submit the intakes assigned to you
              </strong>
              <Bullets>
                <li>Each intake takes about 30-45 minutes to complete</li>
                <li>
                  Fill as much as you can, but don’t worry if some fields are
                  left blank
                </li>
                <li>You can revisit the intakes, even after you submit</li>
              </Bullets>
            </div>
          </div>
        )}
        {page === 3 && (
          <div>
            <div className="centered" style={{ marginTop: "1rem" }}>
              <img
                width="auto"
                alt="person on desktop computer"
                src={DesktopGirl}
              />
            </div>
            <div>
              <h3>Step 2</h3>
              <strong style={{ fontSize: "18px" }}>
                Review your information with our team
              </strong>
              <Bullets>
                <li>
                  Once the intakes for your petition are submitted, set a
                  meeting with us to review your information
                </li>
                <li>
                  Contact{" "}
                  <a href="mailto:customer-success@legalpad.io">
                    customer-success@legalpad.io
                  </a>{" "}
                  if you have questions
                </li>
              </Bullets>
            </div>
          </div>
        )}
        <Breadcrumbs>
          <button
            type="button"
            className="text text-button no-padding"
            style={{
              textAlign: "left",
              visibility: page === 1 ? "hidden" : "visible"
            }}
            onClick={() => handlePrevious()}
          >
            Back
          </button>
          <div className="centered">
            {page === 1 ? <FaCircle /> : <FaRegCircle />}
            {page === 2 ? <FaCircle /> : <FaRegCircle />}
            {page === 3 ? <FaCircle /> : <FaRegCircle />}
          </div>
          <button type="button" onClick={() => handleNext()}>
            {page === 3 ? "Get started!" : "Next"}
          </button>
        </Breadcrumbs>
      </Modal>
    );
  return null;
}

WelcomeFlow.propTypes = {};
