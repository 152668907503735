import React from "react";

export default function AddressDisplay(props) {
  const addressComponents = data => {
    const cityLine = `${data.city}, ${data.region} ${data.postal_code}`;
    let addressValues = [];

    if (
      props.attribute.name === "us_home_address" ||
      props.attribute.name === "us_mailing_address"
    ) {
      addressValues = [data.line_1, data.line_2, data.line_3, cityLine];
    } else {
      addressValues = [
        data.line_1,
        data.line_2,
        data.line_3,
        cityLine,
        data.country
      ];
    }
    return addressValues.filter(el => el);
  };

  return addressComponents(props.components).map(line => (
    <React.Fragment key={line}>
      {line}
      <br />
    </React.Fragment>
  ));
}
