/* eslint-disable react/destructuring-assignment */
import React from "react";
import Form from "../../FormFields/Form";
import EntityFields from "../../FormFields/EntityFields";
import ActionBar from "../../FormFields/ActionBar";

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.attribute = props.attribute;
    this.attributes = props.attribute.attributes;
    this.state = {
      errors: [],
      showActions: false
    };
  }

  _handleInputChange() {
    this.setState({ showActions: true });
  }

  _handleSubmit(e) {
    e.preventDefault();

    const context = this;
    const url = Routes.entity_path(this.props.id);
    const requestBody = new FormData(e.target);

    fetch(url, { method: "PUT", body: requestBody }).then(response => {
      if (response.ok) {
        response.json().then(data => {
          context.props.handleSubmit(data);
        });
      } else {
        response.json().then(errors => {
          context.setState({ errors });
        });
      }
    });
  }

  _renderAttributeInputs() {
    return this.attributes.map(attribute => {
      const inputName = `entity_attributes[${this.attribute.name}_attributes][${attribute.name}]`;
      const { label } = attribute;
      return (
        <div
          className={`text-input-wrapper ${
            this.state.errors.length > 0 ? "has-error" : ""
          }`}
          key={attribute.name}
        >
          <input
            type="text"
            name={inputName}
            attributeName={attribute.name}
            placeholder={label}
            defaultValue={this.props.attributesHash[attribute.name]}
            onChange={() => this._handleInputChange()}
          />
          <label>{label}</label>
        </div>
      );
    });
  }

  render() {
    const inputNameForId = `entity_attributes[${this.attribute.name}_attributes][id]`;

    return (
      <Form onSubmit={e => this._handleSubmit(e)}>
        <EntityFields type={this.props.type} id={this.props.id} />
        <div
          className={`text-input-wrapper ${
            this.state.errors.length > 0 ? "has-error" : ""
          }`}
        >
          {this._renderAttributeInputs()}
          {this.state.errors
            ? this.state.errors.map(error => <p>{error}</p>)
            : null}
        </div>
        <input
          type="hidden"
          name="attribute_name"
          value={this.attribute.name ?? ""}
        />

        <input
          type="hidden"
          name={inputNameForId}
          value={this.attribute.id ?? ""}
        />
        {this.state.showActions ? (
          <ActionBar handleCancel={this.props.handleCancel} />
        ) : null}
      </Form>
    );
  }
}
