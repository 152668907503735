import React from "react";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import { LiveProvider, LiveEditor, LiveError, LivePreview } from "react-live";
import github from "prism-react-renderer/themes/github";
import { Button } from "../Shared/Button/Button";

const ButtonCode = `
  <>
    <Button
      color="primary"
      size="medium"
      style={{ marginRight: "1rem" }}
    > Primary
    </Button>
    <Button
      color="secondary"
      size="medium"
      style={{ marginRight: "1rem" }}
    > Secondary
    </Button>
    <Button
      color="danger"
      size="medium"
      style={{ marginRight: "1rem" }}
    > Danger
    </Button>
  </>
`;

export default function ButtonDocs(props) {
  return (
    <LiveProvider scope={{ Button }} code={ButtonCode} theme={github}>
      <div>
        <h4>
          Buttons allow users to take actions, and make choices, with a single
          tap.
        </h4>
        <p>Buttons come in colors</p>
        <LivePreview />
      </div>

      <LiveEditor
        style={{
          borderRadius: "4px",
          marginTop: "1rem",
          display: "grid"
        }}
      />
      <LiveError />
    </LiveProvider>
  );
}

ButtonDocs.propTypes = {};
