/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import styled from "styled-components";
import { formHeaders } from "../../../utils/form";

const DocumentContainer = styled.div`
  border: 1px solid lightgray;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin: 1rem 0 1rem 0;
  width: 100%;
  i {
    font-size: 2em;
  }
`;

const FileName = styled.h5`
  font-size: 1em;
  width: 10rem;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
`;

const ButtonContainer = styled.div`
  display: flex;

  a {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;

export default function UploadItem(props) {
  const { attribute, file, files, setFiles } = props;

  const handleRemove = async fileId => {
    if (window.confirm("Are you sure you want to remove this file?")) {
      try {
        const params = {
          entity_id: attribute.fileData.entityId,
          entity_type: attribute.fileData.entityType,
          entity_attribute: attribute.name,
          file_id: fileId,
          remove_item: true
        };
        const url = Routes.entity_path(attribute.fileData.entityId);

        const response = await fetch(url, {
          method: "PUT",
          headers: formHeaders(),
          body: JSON.stringify(params)
        });

        if (!response.ok) {
          alert("Something went wrong.");
        }

        if (response.ok) {
          if (files) {
            setFiles(files.filter(f => f.fileId !== fileId));
          } else {
            setFiles();
          }
        }
      } catch (error) {
        alert(error);
      }
    }
  };

  return (
    <DocumentContainer>
      <i className="fas fa-file"></i>
      <FileName>{file.filename}</FileName>
      <ButtonContainer>
        <a href={file.blobPath}>VIEW</a>
        <a className="destructive" onClick={() => handleRemove(file.fileId)}>
          REMOVE
        </a>
      </ButtonContainer>
    </DocumentContainer>
  );
}
