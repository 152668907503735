/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useReducer } from "react";
import Entity from "./FormFields/Entity";
import EntityGroup from "./FormFields/EntityGroup";
import CommonNav from "../Shared/CommonNav/CommonNav";
import ClientInformationNav from "./ClientInformationNav/ClientInformationNav";
import NotesConsole from "../NotesConsole/NotesConsole";

export default function ClientInformation(props) {
  const {
    beneficiaryId = null,
    beneficiaryName = "Betty Beneficiary",
    id = null,
    entities = [],
    filters = []
  } = props;

  const filterStateReducer = (state, action) => {
    switch (action.type) {
      case "SET_SEARCH_STRING":
        return { ...state, searchString: action.searchString };
      case "SET_SOURCE":
        return { ...state, source: action.source };
      case "SET_SECTIONS":
        return { ...state, sections: action.sections };
      case "CLEAR_FILTERS":
        return { source: null, sections: [] };
      default:
        return { ...state };
    }
  };

  const [filterState, dispatchFilter] = useReducer(filterStateReducer, {
    searchString: null,
    source: null,
    sections: []
  });

  const renderEntity = entity => {
    const key = `${entity.name}${entity.id}`;

    if (entity.subtypes) {
      return entity.subtypes.map(e => (
        <span id={entity.name} key={`${key}-${e.name}`}>
          <EntityGroup
            entities={e.entities}
            name={e.name}
            parentId={beneficiaryId}
            filterState={filterState}
            key={`${key}-${e.name}`}
          />
        </span>
      ));
    }
    if (entity.entities) {
      return (
        <EntityGroup
          entities={entity.entities}
          name={entity.name}
          parentId={beneficiaryId}
          filterState={filterState}
          key={key}
        />
      );
    }
    return <Entity entity={entity} filterState={filterState} key={key} />;
  };

  // eslint-disable-next-line no-shadow
  const filterEntities = entities => {
    const entitiesWithSource = entities.filter(entity => entity.source);
    const entitiesWithoutSource = entities.filter(entity => !entity.source);

    let filteredEntitiesWithSource = entitiesWithSource;

    // Filter based on entity source first
    if (filterState.source) {
      filteredEntitiesWithSource = entitiesWithSource.filter(
        entity => filterState.source === entity.source
      );
    }

    // Then filter based on sections array if not empty
    if (filterState.sections.length > 0) {
      filteredEntitiesWithSource = filteredEntitiesWithSource.filter(entity =>
        filterState.sections.includes(entity.section)
      );
    }

    // Lastly handle our search filter using the entity name
    if (filterState.searchString) {
      filteredEntitiesWithSource = filteredEntitiesWithSource.filter(entity => {
        // Unfurl some names from our entities for search purposes
        let subTypeNames = [];
        if (entity.subtypes) {
          subTypeNames = entity.subtypes.map(subtype =>
            subtype.name.toLowerCase()
          );
        }
        const searchables = [
          entity.name.toLowerCase(),
          ...subTypeNames
        ].toString();

        return searchables.includes(
          filterState.searchString.trim().toLowerCase()
        );
      });
    }

    // Always return the entities without source whose attributes will be
    // filtered in the <Entity> component
    return [...entitiesWithoutSource, ...filteredEntitiesWithSource];
  };

  return (
    <>
      <CommonNav
        home={{ text: "Petitions", href: "/petitions" }}
        title={{ text: beneficiaryName, href: `/petitions/${id}` }}
        subTitle={{
          text: "Edit Client Information",
          href: `/petitions/${id}/edit`
        }}
      />
      <ClientInformationNav
        id={id}
        entities={entities}
        filters={filters}
        filterState={filterState}
        dispatchFilter={dispatchFilter}
      />
      <main className="client-information">
        {filterEntities(entities).map(entity => renderEntity(entity))}
      </main>
      <NotesConsole
        source="Client Information"
        notable={{ id }}
        notableType="Petition"
      />
    </>
  );
}
