/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ProgressTrackerModal from "../Shared/Modal/ProgressTrackerModal";
import { blueDark, grayDark, grayDarker } from "../../styles/colors";

const SplitSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  .left-panel {
    padding-right: 1.5rem;
    border-right: 1px solid #e9edf0;
    display: flex;
    justify-content: center;
    align-items: center;

    h4 {
      margin-bottom: 24px;
    }
  }

  .big-blue {
    font-size: 24px;
    color: ${blueDark};
    margin-right: 1.5rem;
  }

  .step-count {
    float: right;
    font-size: 16px;
    font-weight: 400;
    color: ${grayDark};
  }

  h3 {
    margin: 0;
  }
`;

const RightPanel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1.5rem;

  strong {
    font-size: 1em !important;
    font-weight: 700;
    color: ${grayDarker};
  }

  p {
    font-size: 0.875em !important;
    font-weight: 400;
    color: ${grayDarker};
  }
`;

const Breadcrumbs = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;

  svg {
    margin: 0.25rem;
    font-size: 0.5em;
  }
`;

export default function ProgressStepFlow({ currentStep, setModalOpen }) {
  const [page, setPage] = useState(currentStep);

  const numPages = 4;

  const handleNext = () => {
    setPage(prev => prev + 1);
  };

  const handlePrevious = () => {
    setPage(prev => prev - 1);
  };

  useEffect(() => {
    if (page > numPages) setModalOpen(false);
  }, [page]);

  if (page <= numPages)
    return (
      <ProgressTrackerModal show cleanup={() => setModalOpen(false)}>
        {page === 0 && (
          <SplitSection>
            <div className="left-panel">
              <div>
                <h4>
                  <span className="big-blue">1</span>Sign Agreements
                  <span className="step-count">1 of 5</span>
                </h4>
                <p>
                  Congratulations! You've already completed the first step
                  towards obtaining your visa. Please be sure to adhere to the
                  terms of your agreement to ensure a smooth process.
                </p>
              </div>
            </div>
            <RightPanel>
              <div>
                <strong>Keep moving forward</strong>
                <p>
                  Common blockers to your visa process can include late
                  payments, missing documents, or delays to correspondence. Keep
                  a close eye on your inbox for any updates shared by your
                  Immigration Specialist!
                </p>
              </div>
            </RightPanel>
          </SplitSection>
        )}
        {page === 1 && (
          <SplitSection>
            <div className="left-panel">
              <div>
                <h4>
                  <span className="big-blue">2</span>Data Gathering
                  <span className="step-count">2 of 5</span>
                </h4>
                <p>
                  The intakes on your dashboard aim to learn all about you so we
                  can craft the strongest petition on your behalf.
                </p>
              </div>
            </div>
            <RightPanel>
              <div>
                <strong>Brag about yourself</strong>
                <p>
                  It’s essential to share as much detailed information as you
                  can about yourself and your company.
                </p>
                <strong>Reach out with questions</strong>
                <p>
                  Your Immigration Specialist is available and eager to help
                  with any questions you may have about the intakes
                </p>
                <strong>What’s next</strong>
                <p>
                  Once all your forms have been submitted, you’ll meet with your
                  Immigration Specialist to review and polish your information.
                </p>
              </div>
            </RightPanel>
          </SplitSection>
        )}
        {page === 2 && (
          <SplitSection>
            <div className="left-panel">
              <div>
                <h4>
                  <span className="big-blue">3</span>Build Petition
                  <span className="step-count">3 of 5</span>
                </h4>
                <p>
                  This step is where the bulk of the work happens on your
                  petition.
                </p>
              </div>
            </div>
            <RightPanel>
              <div>
                <strong>What we do</strong>
                <p>
                  We’ll draft letters and evidence on your behalf. By the end of
                  this stage, the first draft of your petition will be
                  completed.
                </p>
                <strong>What we need from you</strong>
                <p>
                  You'll need to review statements your Immigration Specialist
                  has drafted for your petition and contact your verifiers.
                  Please notify your verifiers that they will need to review and
                  sign letters on your behalf.
                </p>
                <strong>What’s next</strong>
                <p>
                  We’ll compile the petition and circulate it for final reviews
                  and signatures before we ship.
                </p>
              </div>
            </RightPanel>
          </SplitSection>
        )}
        {page === 3 && (
          <SplitSection>
            <div className="left-panel">
              <div>
                <h4>
                  <span className="big-blue">4</span>Awaiting Decision
                  <span className="step-count">4 of 5</span>
                </h4>
                <p>
                  Your Immigration Specialist has shipped your petition and
                  USCIS has issued a receipt number.
                </p>
              </div>
            </div>
            <RightPanel>
              <div>
                <strong>How do I keep track of my case?</strong>
                <p>
                  You can enter the receipt number (e.g. ABC123456789) to keep
                  track of your case on the{" "}
                  <a href="https://egov.uscis.gov/casestatus/landing.do">
                    USCIS Website
                  </a>
                </p>
                <strong>What’s next</strong>
                <p>
                  Once USCIS reaches a decision on your case, we will provide
                  more information with post-decision steps
                </p>
              </div>
            </RightPanel>
          </SplitSection>
        )}
        {page === 4 && (
          <SplitSection>
            <div className="left-panel">
              <div>
                <h4>
                  <span className="big-blue">5</span>USCIS Decision
                  <span className="step-count">5 of 5</span>
                </h4>
                <p>
                  USCIS has provided a decision for your petition. Please reach
                  out to your Immigration Specialist for details.
                </p>
              </div>
            </div>
            <RightPanel>
              <div>
                <strong>What does this mean</strong>
                <p>
                  Your case has been reviewed and a decision has been issued.
                </p>
              </div>
            </RightPanel>
          </SplitSection>
        )}
        <Breadcrumbs>
          <button
            type="button"
            className="text text-button no-padding"
            style={{
              textAlign: "left",
              visibility: page === 0 ? "hidden" : "visible"
            }}
            onClick={() => handlePrevious()}
          >
            Previous
          </button>
          <div className="centered"></div>
          <button type="button" onClick={() => handleNext()}>
            {page === numPages ? "Got it!" : "Next Step"}
          </button>
        </Breadcrumbs>
      </ProgressTrackerModal>
    );
  return null;
}
