import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useAlert } from "react-alert";
import moment from "moment";
import CommonNav from "../Shared/CommonNav/CommonNav";
import ClientIntakes from "./ClientIntakes";
import { gray, blue, grayLight, grayDark, green } from "../../styles/colors";
import Dropdown from "../Shared/Dropdown/__Dropdown";
import request from "../../services/request";
import { sendConfirmationEmail } from "../../services/emails";
import { useAuthContext } from "../Shared/ContextProviders/AuthProvider";

const Styles = styled.div`
  button {
    padding: 1rem;
    min-width: 8rem;
    &.secondary {
      border: 1px solid ${gray};
      color: ${blue};
      background: #fff;
      &:hover {
        background: ${grayLight};
      }
    }
  }

  .heading {
    width: fit-content;
    display: flex;
    align-items: baseline;
    margin-bottom: 0.5rem;

    h3 {
      margin: 0 1.5rem 0 0;
    }
    a {
      margin: 0;
      align-items: "center";
    }
  }
`;

const DateGrid = styled.div`
  margin: 1.5rem 0;
  display: grid;
  width: fit-content;
  grid-gap: 2rem;
  grid-template-columns: repeat(3, auto);
  span {
    display: block;
    p {
      color: ${grayDark};
      font-weight: 500;
      margin: 0;
    }
  }
`;

export default function ClientShowPage({ client }) {
  const popup = useAlert();

  const {
    fullName,
    id,
    email,
    phone,
    welcomeEmailSentAt,
    confirmedAt,
    currentSignInAt
  } = client;

  const [welcomeEmailSentAtState, setWelcomeEmailSentAtState] = useState(
    welcomeEmailSentAt
  );

  const handleSendWelcomeEmail = async () => {
    const response = await sendConfirmationEmail(id, popup);
    setWelcomeEmailSentAtState(response.welcomeEmailSentAt);
  };

  const { features } = useAuthContext();

  const handleDestroy = async () => {
    try {
      await request(Routes.client_path(id), {
        method: "DELETE"
      });
      window.location.replace(Routes.clients_path());
      popup.show("Client has been destroyed successfully.", {
        type: "success"
      });
    } catch (error) {
      popup.show(error.message, { type: "error" });
    }
  };

  return (
    <main style={{ margin: "7rem 3rem" }}>
      <Styles>
        <CommonNav
          home={{ text: "Clients", href: "/clients" }}
          title={{ text: fullName, href: "#" }}
        />

        <div className="heading">
          <h3>{fullName}</h3>
          <a href={Routes.person_path(id)}>
            <i className="fas fa-external-link-alt"></i>{" "}
            <span style={{ textDecoration: "underline" }}>
              Edit information
            </span>
          </a>
        </div>
        <div className="heading">
          <a
            href={`mailto:${email}`}
            style={{ color: "inherit", marginRight: "1rem" }}
          >
            {email}
          </a>
          <p>{phone}</p>
        </div>
        {welcomeEmailSentAtState && (
          <DateGrid>
            <span>
              <p>Invite Sent</p>
              {welcomeEmailSentAtState
                ? moment(welcomeEmailSentAtState).format("MM/DD/YYYY")
                : "-"}
            </span>
            <span>
              <p>Account Activated</p>
              <div>
                <i
                  className="fas fa-check-circle"
                  style={{
                    marginRight: "1rem",
                    color: green
                  }}
                />
                {confirmedAt ? moment(confirmedAt).format("MM/DD/YYYY") : "-"}
              </div>
            </span>
            <span>
              <p>Last Accessed</p>
              {currentSignInAt
                ? moment(currentSignInAt).format("MM/DD/YYYY")
                : "-"}
            </span>
          </DateGrid>
        )}
        {features.welcomeEmailButton && !welcomeEmailSentAtState && (
          <button type="button" onClick={() => handleSendWelcomeEmail()}>
            Send welcome email
          </button>
        )}
        {features.welcomeEmailButton && welcomeEmailSentAtState && (
          <button type="button" onClick={() => handleSendWelcomeEmail()}>
            Resend welcome email
          </button>
        )}
        <section>
          <ClientIntakes clientId={id} />
          <Dropdown name="Actions">
            <button
              tabIndex={0}
              type="button"
              className="button small destructive"
              onKeyDown={() =>
                window.confirm(
                  "Are you sure you want to destroy this client?"
                ) && handleDestroy()
              }
              onClick={() =>
                window.confirm(
                  "Are you sure you want to destroy this client?"
                ) && handleDestroy()
              }
            >
              Destroy this client
            </button>
          </Dropdown>
        </section>
      </Styles>
    </main>
  );
}

ClientShowPage.propTypes = {
  client: PropTypes.object
};
