import { render, waitFor } from "@testing-library/react";
import { Provider as AlertProvider } from "react-alert";
import { QueryClient, QueryClientProvider } from "react-query";
import { PetitionsPage } from "./PetitionsPage";
import AlertTemplate from "../Shared/AlertTemplate";

const queryClient = new QueryClient();

function WrappedPetitionsPage() {
  return (
    <QueryClientProvider client={queryClient}>
      <AlertProvider template={AlertTemplate}>
        <PetitionsPage />
      </AlertProvider>
    </QueryClientProvider>
  );
}

describe("The PetitionsPage component", () => {
  beforeEach(() => {
    queryClient.clear();
    fetch.once(
      JSON.stringify({
        petitions: [
          {
            id: "1186b820-54e3-4eea-b309-cac2b3615811",
            progress: "data gather",
            updatedAt: "2021-04-08T14:29:06.791-07:00",
            beneficiaryName: "Wiley Conte",
            workflowName: "O-1A",
            organizationName: "Megacorp",
            intakes: [
              {
                id: "51886a98-3767-45fc-b679-445123177df1",
                userId: "780efdcf-3f03-5fc7-a372-58b1d1d14c87",
                userName: "Wiley Conte",
                intakeType: "JobIntake",
                startedAt: null,
                submittedAt: null,
                intakeLabel: "Employment Information",
                companyName: "Megacorp"
              },
              {
                id: "409d06c6-7599-4797-b175-610791fc88fe",
                userId: "780efdcf-3f03-5fc7-a372-58b1d1d14c87",
                userName: "Wiley Conte",
                intakeType: "ExceptionalAbilityIntake",
                startedAt: null,
                submittedAt: null,
                intakeLabel: "Extraordinary Ability Details",
                companyName: "Megacorp"
              },
              {
                id: "580eea10-6d98-4d06-b542-da1b0ac69dc2",
                userId: "780efdcf-3f03-5fc7-a372-58b1d1d14c87",
                userName: "Wiley Conte",
                intakeType: "ApplicantIntake",
                startedAt: "2021-04-12T12:37:58.550-07:00",
                submittedAt: null,
                intakeLabel: "Personal Information",
                companyName: "Megacorp"
              },
              {
                id: "21b22a1f-fec3-47e5-93dd-8508254461e9",
                userId: "5a0111bb-0c6e-40bd-bd66-76d04667f211",
                userName: "Anna Applesauce",
                intakeType: "ExceptionalAbilityCompanyIntake",
                startedAt: null,
                submittedAt: null,
                intakeLabel: "Company Details",
                companyName: "Megacorp"
              }
            ]
          },
          {
            id: "bbd1a69a-349e-49f5-a712-5d446fea818d",
            progress: "agreement signed",
            updatedAt: "2021-04-08T14:29:06.743-07:00",
            beneficiaryName: "Wiley Conte",
            workflowName: "H-1B",
            organizationName: "Megacorp",
            intakes: [
              {
                id: "d56d0b0c-46e8-4f58-b167-57b9456a7929",
                userId: "780efdcf-3f03-5fc7-a372-58b1d1d14c87",
                userName: "Wiley Conte",
                intakeType: "JobIntake",
                startedAt: null,
                submittedAt: null,
                intakeLabel: "Employment Information",
                companyName: "Megacorp"
              },
              {
                id: "22a9b024-05e9-4292-98e7-8b8fa8689366",
                userId: "780efdcf-3f03-5fc7-a372-58b1d1d14c87",
                userName: "Wiley Conte",
                intakeType: "ApplicantIntake",
                startedAt: null,
                submittedAt: null,
                intakeLabel: "Personal Information",
                companyName: "Megacorp"
              },
              {
                id: "121628a9-f8b9-4609-a816-c6660713480c",
                userId: "5a0111bb-0c6e-40bd-bd66-76d04667f211",
                userName: "Anna Applesauce",
                intakeType: "CompanyIntake",
                startedAt: null,
                submittedAt: null,
                intakeLabel: "Company Details",
                companyName: "Megacorp"
              }
            ]
          }
        ],
        notice: {
          message:
            "We still need more information from you! Keep up the good work",
          type: "warning"
        }
      })
    );
  });

  xit("renders without crashing", async () => {
    const { findByText } = render(<WrappedPetitionsPage />);

    // expect(await findByText("Petitions")).toBeInTheDocument();

    await waitFor(() => expect(fetch.mock.calls.length).toEqual(1));
  });

  it("displays the progress dots correctly", () => {
    const { container } = render(<WrappedPetitionsPage />);
  });
});
