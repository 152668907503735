/* eslint-disable react/destructuring-assignment */
import React from "react";
import Entity from "./Entity";
import MembershipEntity from "./MembershipEntity";
import Form from "../../FormFields/Form";

export default class EntityGroup extends React.Component {
  // See ClientInformation for documentation

  constructor(props) {
    super(props);
    this.state = {
      entities: props.entities.filter(e => e.id),
      // eslint-disable-next-line react/no-unused-state
      error: ""
    };
  }

  _removeEntityFromState(id) {
    // If an entity is removed further down the tree,
    // remove it from the state object & the DOM.

    // eslint-disable-next-line react/no-access-state-in-setstate
    const newEntities = this.state.entities.filter(e => e.id !== id) || [];
    this.setState({ entities: newEntities });
  }

  _addEntityToState(id) {
    // When an entity is created via ajax call, add it
    // to the state object inline so we dont have to reload.

    // eslint-disable-next-line react/no-access-state-in-setstate
    const newEntities = this.state.entities;
    const dup = { ...this.props.entities[0] };
    dup.id = id;
    dup.name = this.props.name;
    dup.attributes.forEach(a => {
      delete a.value;
      delete a.id;

      // Check if attribute has nested attributes (e.g. for Contacts and Addresses)
      // Delete the nested values and IDs
      if (a.attributes) {
        a.attributes.forEach(nestedA => {
          delete nestedA.value;
          delete nestedA.id;
        });
      }
    });
    newEntities.push(dup);
    this.setState({ entities: newEntities });
  }

  _handleSubmit(e) {
    e.preventDefault();

    const context = this;
    const url = Routes.entity_index_path();
    const data = new FormData(e.target);

    fetch(url, { method: "POST", body: data }).then(response => {
      if (response.ok) {
        response.json().then(d => {
          this._addEntityToState(d.id);
        });
      } else {
        response.json().then(error => {
          context.setState({ error });
        });
      }
    });
  }

  _renderEntity(entity, index) {
    if (entity.type === "Membership") {
      return (
        <MembershipEntity
          entity={entity}
          secondary
          index={index}
          inputFilter={this.props.inputFilter}
          key={`${entity.name}${entity.id}`}
          removeEntityFromState={id => this._removeEntityFromState(id)}
          filterState={this.props.filterState}
        />
      );
    }
    return (
      <Entity
        entity={entity}
        secondary
        index={index}
        key={`${entity.name}${entity.id}`}
        inputFilter={this.props.inputFilter}
        removeEntityFromState={id => this._removeEntityFromState(id)}
        filterState={this.props.filterState}
      />
    );
  }

  _renderForm() {
    const entity = this.props.entities[0];
    const { type } = entity;
    const associatedAs = entity.associatedAs || "person";
    const membershipBodyType = entity.subtype;

    return (
      <Form onSubmit={e => this._handleSubmit(e)}>
        <input type="hidden" name="entity_type" value={type} />
        <input type="hidden" name="parent_type" value="Person" />
        <input type="hidden" name="parent_id" value={this.props.parentId} />
        <input type="hidden" name="associated_as" value={associatedAs} />
        {type === "Membership" && (
          <input
            type="hidden"
            name="entity_attributes[membership_body_type]"
            value={membershipBodyType}
          />
        )}

        <div className="action-bar small">
          <button type="submit">Add {this.props.name}</button>
        </div>
      </Form>
    );
  }

  render() {
    return (
      <section id={this.props.name}>
        <h3>{this.props.name}</h3>
        {this.state.entities.map((entity, index) =>
          this._renderEntity(entity, index)
        )}
        {this._renderForm()}
      </section>
    );
  }
}
