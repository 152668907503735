import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import UploadItem from "./UploadItem";
import FileUpload from "../../Shared/FileUpload/FileUpload";

export default function FileFieldContainer(props) {
  const { attribute, handleUpload, loading, setLoading } = props;

  const { fileData = {} } = attribute;

  const [files, setFiles] = useState(fileData.files);

  useEffect(() => {
    setFiles(fileData.files);
  }, [fileData.files]);

  return (
    <>
      {files &&
        files.map(file => (
          <UploadItem
            key={file.blobPath}
            attribute={attribute}
            file={file}
            files={files}
            setFiles={setFiles}
          />
        ))}
      {!loading &&
        (files?.length === 0 || attribute.inputType === "attachments") && (
          <FileUpload
            multiple={false}
            handleUpload={handleUpload}
            attribute={attribute}
            setLoading={setLoading}
            filesPresent={files?.length > 1}
          />
        )}
      {loading && (
        <div className="u_margin-left-3rem">
          <Loader type="ThreeDots" color="#2680FA" height={64} width={64} />
        </div>
      )}
    </>
  );
}
