/* eslint-disable react/destructuring-assignment */
import React from "react";
import Form from "../../FormFields/Form";
import AutocompleteField from "../../Autocomplete/AutocompleteField";
import EntityFields from "../../FormFields/EntityFields";
import ActionBar from "../../FormFields/ActionBar";

export default class AddressForm extends React.Component {
  // See ClientInformation for documentation

  constructor(props) {
    super(props);
    this.attribute = props.attribute;
    this.attributes = props.attribute.attributes;
    this.state = {
      errors: [],
      showActions: false
    };
  }

  _handleInputChange() {
    this.setState({ showActions: true });
  }

  _handleSubmit(e) {
    e.preventDefault();

    const context = this;
    const url = Routes.entity_path(this.props.id);
    const requestBody = new FormData(e.target);

    fetch(url, { method: "PUT", body: requestBody }).then(response => {
      if (response.ok) {
        response.json().then(data => {
          context.props.handleSubmit(data);
        });
      } else {
        response.json().then(errors => {
          context.setState({ errors });
        });
      }
    });
  }

  _renderHiddenCountryInput() {
    return (
      <input
        type="hidden"
        name={`entity_attributes[${this.attribute.name}_attributes][country`}
        value="United States"
      />
    );
  }

  _renderAttributeInputs() {
    return this.attributes.map(attribute => {
      const inputName = `entity_attributes[${this.attribute.name}_attributes][${attribute.name}]`;
      const label =
        this.props.usAddress && attribute.name === "region"
          ? "State"
          : attribute.label;

      if (attribute.name === "postal_code") {
        return (
          <div
            className={`text-input-wrapper inline tiny ${
              this.state.errors.length > 0 ? "has-error" : ""
            }`}
            key={attribute.name}
          >
            <input
              type="text"
              name={inputName}
              attributeName={attribute.name}
              placeholder={label}
              defaultValue={this.props.attributesHash[attribute.name]}
              onChange={() => this._handleInputChange()}
            />
          </div>
        );
      }

      if (attribute.name === "country") {
        return (
          <div
            className={`text-input-wrapper inline inline-next-to-tiny ${
              this.state.errors.length > 0 ? "has-error" : ""
            }`}
            key={attribute.name}
          >
            <AutocompleteField
              id={this.props.attributesHash[attribute.name]}
              value={this.props.attributesHash[attribute.name]}
              items={attribute.options}
              name={inputName}
              type="string"
              placeholder={label}
              label={label}
              onParentChange={() => this._handleInputChange()}
            />
          </div>
        );
      }

      if (
        attribute.inputType === "autocomplete" &&
        ((this.props.usAddress && attribute.name === "region") ||
          (!this.props.usAddress && attribute.name === "country"))
      ) {
        return (
          <div
            className={`text-input-wrapper ${
              this.state.errors.length > 0 ? "has-error" : ""
            }`}
            key={attribute.name}
          >
            <AutocompleteField
              id={this.props.attributesHash[attribute.name]}
              value={this.props.attributesHash[attribute.name]}
              items={attribute.options}
              name={inputName}
              type="string"
              placeholder={label}
              label={label}
              onParentChange={() => this._handleInputChange()}
            />
          </div>
        );
      }
      return (
        <div
          className={`text-input-wrapper ${
            this.state.errors.length > 0 ? "has-error" : ""
          }`}
          key={attribute.name}
        >
          <input
            type="text"
            name={inputName}
            attributeName={attribute.name}
            placeholder={label}
            defaultValue={this.props.attributesHash[attribute.name]}
            onChange={() => this._handleInputChange()}
          />
          <label>{label}</label>
        </div>
      );
    }, this);
  }

  render() {
    return (
      <Form onSubmit={e => this._handleSubmit(e)}>
        <EntityFields type={this.props.type} id={this.props.id} />
        <div
          className={`text-input-wrapper ${
            this.state.errors.length > 0 ? "has-error" : ""
          }`}
        >
          {this._renderAttributeInputs()}
          {this.state.errors
            ? this.state.errors.map(error => <p>{error}</p>)
            : null}
        </div>
        {this.props.usAddress ? this._renderHiddenCountryInput() : null}
        <input type="hidden" name="associated_as" value="Address" />
        <input
          type="hidden"
          name="attribute_name"
          value={this.attribute.name}
        />
        {this.state.showActions ? (
          <ActionBar handleCancel={this.props.handleCancel} />
        ) : null}
      </Form>
    );
  }
}
