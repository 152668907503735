import React from "react";

export default function TypographyDocs() {
  return (
    <>
      <article id="typography">
        <h1 className="thin">Typography</h1>
        <p>We have standardized the look and feel of common html elements</p>
        <h1>H1 – 42px</h1>
        <h2>H2 - 36px</h2>
        <h3>H3 - 24px</h3>
        <h4>H4 - 21px</h4>
        <h5>H5 - 18px</h5>
        <h6>H6 - 16px</h6>
        <div className="font-size-smallest u_margin-bottom-1rem">
          .font-size-smallest - 14px
        </div>
        <p>
          This is a paragraph. Its text-size is 16px. Use the strong tag to{" "}
          <strong>make text bold inside a paragraph</strong>. It's got some{" "}
          <a href="">links</a> in it. To improve readability, paragraphs have a
          maximum width.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur <a href="">adipiscing elit</a>
          , sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
          nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </p>
        <h5>Here's a list:</h5>
        <ul>
          <li>List item</li>
          <li>Another list item</li>
          <li>Third list item</li>
        </ul>
        <h5>Here's a bulleted list:</h5>
        <ul className="bulleted">
          <li>List item</li>
          <li>Another list item</li>
          <li>Third list item</li>
        </ul>
      </article>
    </>
  );
}

TypographyDocs.propTypes = {};
