import React from "react";
import PropTypes from "prop-types";
import { BsCircleFill, BsCircle, BsCircleHalf } from "react-icons/bs";

export function ProgressDots({ progressEnum }) {
  return (
    <>
      {[...Array(5)].map((_, i) => {
        switch (true) {
          case i === 0:
            return (
              <BsCircleFill
                data-testid="progress-dot"
                className="full-circle"
                key={i}
              />
            );
          case i === 4 && i === progressEnum:
            return (
              <BsCircleFill
                data-testid="progress-dot"
                className="full-circle"
                key={i}
              />
            );
          case i === progressEnum:
            return (
              <BsCircleHalf
                data-testid="progress-dot"
                className="half-circle"
                style={{ transform: "rotate(180deg)" }}
                key={i}
              />
            );
          case i > progressEnum:
            return (
              <BsCircle
                data-testid="progress-dot"
                className="empty-circle"
                key={i}
              />
            );
          default:
            return (
              <BsCircleFill
                data-testid="progress-dot"
                className="full-circle"
                key={i}
              />
            );
        }
      })}
    </>
  );
}

ProgressDots.propTypes = {
  progressEnum: PropTypes.number
};
