import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { LiveEditor, LiveError, LivePreview, LiveProvider } from "react-live";
import github from "prism-react-renderer/themes/github";
import AutoSuggest from "../../Shared/AutoSuggest/AutoSuggest";

const testItems = [
  {
    value: 123,
    label: "English"
  },
  {
    value: 345,
    label: "Spanish"
  },
  {
    value: 678,
    label: "French"
  },
  {
    value: 252,
    label: "Italian"
  },
  {
    value: 989,
    label: "German"
  },
  {
    value: 586,
    label: "Korean"
  }
];

const autosuggestCode = `
  <AutoSuggest
    label="Language"
    placeholder="Choose a language or enter a new one..."
    items={testItems}
    handleSelect={() => {}}
    handleInputChange={() => {}}
  />
`;

export default function AutoSuggestDocs(props) {
  return (
    <>
      <h4>
        Our autosuggest component allows a user to select from a large list of
        preloaded options or enter in a new option.
      </h4>

      <div>
        <LiveProvider
          scope={{ AutoSuggest, testItems }}
          code={autosuggestCode}
          theme={github}
        >
          <div>
            <LivePreview />
          </div>

          <LiveEditor
            style={{
              borderRadius: "4px",
              margin: "1rem 0",
              display: "grid"
            }}
          />
          <LiveError />
        </LiveProvider>
      </div>
    </>
  );
}

AutoSuggestDocs.propTypes = {};
