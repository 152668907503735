import React from "react";
import { Link } from "react-router-dom";
import SectionsMenu from "../../PetitionPage/SectionsMenu";

export default function SideNav(props) {
  const { sections } = props;
  return (
    <nav>
      <div className="nav-content-wrapper">
        <SectionsMenu sections={sections} />
        <div className="nav-item external">
          <h6>TOOLS</h6>
          <Link to="/deals/new" style={{ padding: "1rem 0" }}>
            <i className="fas fa-plus u_margin-right-1rem"></i>
            New Deal
          </Link>
        </div>
      </div>
    </nav>
  );
}
