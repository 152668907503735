/* eslint-disable react/destructuring-assignment */
import React from "react";
import AttributeItem from "./AttributeItem";
import AddressItem from "./AddressItem";
import ContactItem from "./ContactItem";
import AttributeForm from "./AttributeForm/AttributeForm";

export default function AttributeRow(props) {
  const renderAttribute = () => {
    if (props.attribute.inputType === "address") {
      return (
        <AddressItem
          type={props.type}
          id={props.id}
          attribute={props.attribute}
        />
      );
    }

    if (props.attribute.inputType === "contact") {
      return (
        <ContactItem
          type={props.type}
          id={props.id}
          attribute={props.attribute}
        />
      );
    }

    if (
      props.attribute.inputType === "attachment" ||
      props.attribute.inputType === "attachments"
    ) {
      return (
        <div className="attribute-item attribute">
          <h6 className="label">{props.attribute.label}</h6>
          <AttributeForm
            id={props.id}
            attribute={props.attribute}
            type={props.type}
            value={props.attribute.value}
          />
        </div>
      );
    }

    return (
      <AttributeItem
        type={props.type}
        id={props.id}
        attribute={props.attribute}
        readonly={props.readonly}
        onChange={props.onChange}
        noSubmit={props.noSubmit}
      />
    );
  };

  return (
    <div className={`attribute-row${props.readonly ? " read-only" : ""}`}>
      {renderAttribute()}
      {
        <>
          <EntityDescriptor
            label="Description"
            descriptor={props.attribute.description}
            className="attribute-item description"
          />
        </>
      }
    </div>
  );
}

function EntityDescriptor(props) {
  return (
    <div className={props.className}>
      <div className="label small">{props.label}</div>
      <div className="value small">{props.descriptor}</div>
    </div>
  );
}
