import React from "react";
import PropTypes from "prop-types";
import { Provider as AlertProvider, positions } from "react-alert";
import { QueryClient, QueryClientProvider } from "react-query";
import ClientShowPage from "./ClientShowPage";
import AlertTemplate from "../Shared/AlertTemplate";
import { AuthContextProvider } from "../Shared/ContextProviders/AuthProvider";

const queryClient = new QueryClient();

const options = {
  position: positions.TOP_RIGHT,
  timeout: 3500,
  offset: "30px"
};

export default function ClientShowPageWrapper({ client }) {
  return (
    <QueryClientProvider client={queryClient}>
      <AlertProvider template={AlertTemplate} {...options}>
        <AuthContextProvider>
          <ClientShowPage client={client} />
        </AuthContextProvider>
      </AlertProvider>
    </QueryClientProvider>
  );
}

ClientShowPageWrapper.propTypes = {
  client: PropTypes.object
};
