/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Loader from "react-loader-spinner";
import { useAlert } from "react-alert";
import { QueryClient, QueryClientProvider } from "react-query";
import { formHeaders } from "../../utils/form";
import { IndexRow } from "../Shared/IndexRow/IndexRow";
import CommonNav from "../Shared/CommonNav/CommonNav";
import SearchBar from "../Shared/SearchBar/SearchBar";
import { PaginationWidget } from "../Shared/PaginationWidget/PaginationWidget";
import { usePagination } from "../../hooks/usePagination";
import ClientForm from "./ClientForm";
import { alphabeticalSort, chronologicalSort } from "../../utils/sorting";
import ClientIndexRow from "./ClientIndexRow";

const queryClient = new QueryClient();

const ClientTable = styled.table`
  display: grid;
`;

export default function ClientIndexPage() {
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState(clients);

  const [searchString, setSearchString] = useState("");

  const maxPerPage = 5;

  const popup = useAlert();

  useEffect(() => {
    const filtered = clients
      ? clients.filter(emp => {
          const searchables = [emp.fullName, emp.email]
            .toString()
            .toLowerCase();
          return searchables.includes(searchString.trim().toLowerCase());
        })
      : [];
    setFilteredClients(filtered);
  }, [searchString, clients]);

  const [loading, setLoading] = useState(false);

  const fetchClients = async () => {
    setLoading(true);
    try {
      const url = Routes.clients_path();

      const response = await fetch(url, {
        method: "GET",
        headers: formHeaders()
      });

      if (!response.ok) {
        popup.show("Something went wrong", { type: "error" });
      }

      if (response.ok) {
        const json = await response.json();
        setClients(json.clients);
        setSearchString("");
      }
    } catch (error) {
      popup.show(error.message, { type: "error" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  // Sorting

  const [sortBy, setSortBy] = useState({
    attribute: "name",
    ascending: true
  });

  const handleSort = attribute => {
    setSortBy(prevState => ({
      attribute,
      ascending: prevState.attribute === attribute ? !sortBy.ascending : true
    }));
  };

  const currentSortFunction = (a, b) => {
    switch (sortBy.attribute) {
      case "name":
        return alphabeticalSort(a.lastName, b.lastName, sortBy.ascending);
      case "email":
        return alphabeticalSort(a.email, b.email, sortBy.ascending);
      case "organizations":
        return alphabeticalSort(
          a.organizationNames.toString(),
          b.organizationNames.toString(),
          sortBy.ascending
        );
      case "inviteSent":
        return chronologicalSort(
          a.welcomeEmailSentAt,
          b.welcomeEmailSentAt,
          sortBy.ascending
        );
      case "confirmed":
        return chronologicalSort(
          a.confirmedAt,
          b.confirmedAt,
          sortBy.ascending
        );
      case "createdAt":
        return chronologicalSort(a.createdAt, b.createdAt, sortBy.ascending);
      case "currentSignIn":
        return chronologicalSort(
          a.currentSignInAt,
          b.currentSignInAt,
          sortBy.ascending
        );
      default:
        return alphabeticalSort(a.lastName, b.lastName, sortBy.ascending);
    }
  };

  const currentSortArrow = attribute => {
    if (sortBy.attribute === attribute && sortBy.ascending) return "↑";
    if (sortBy.attribute === attribute && !sortBy.ascending) return "↓";
  };

  const sortedClients = filteredClients.sort((a, b) =>
    currentSortFunction(a, b)
  );

  // Pagination

  const {
    nextPage,
    prevPage,
    currentPage,
    maxPage,
    showAll,
    repaginate,
    currentData,
    startIndex,
    endIndex
  } = usePagination(sortedClients, maxPerPage);

  const [showingAll, setShowingAll] = useState(false);

  const handleShowAllClick = () => {
    showAll();
    setShowingAll(true);
  };

  const handleRepaginateClick = () => {
    repaginate();
    setShowingAll(false);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <CommonNav />
      <main className="client-index" style={{ margin: "5rem 3rem" }}>
        <h1>Clients</h1>
        {loading && (
          <Loader type="ThreeDots" color="#2680FA" height={64} width={64} />
        )}
        <div style={{ margin: "1.5rem 0" }}>
          <SearchBar
            id="client-index-searchbar"
            dataTestId="client-index-searchbar"
            placeholder="Search for Legalpad clients"
            searchStringValue={searchString}
            setSearchStringEvent={setSearchString}
          />
        </div>
        {!loading && !!filteredClients.length && (
          <>
            <ClientTable>
              <tbody>
                <IndexRow columns={6} withPagination clickable>
                  <th
                    onClick={() => handleSort("name")}
                    onKeyDown={() => handleSort("name")}
                  >
                    Name {currentSortArrow("name")}
                  </th>
                  <th
                    onClick={() => handleSort("email")}
                    onKeyDown={() => handleSort("email")}
                  >
                    Email {currentSortArrow("email")}
                  </th>
                  <th
                    onClick={() => handleSort("organizations")}
                    onKeyDown={() => handleSort("organizations")}
                  >
                    Organizations {currentSortArrow("organizations")}
                  </th>
                  <th
                    onClick={() => handleSort("inviteSent")}
                    onKeyDown={() => handleSort("inviteSent")}
                  >
                    Invite Sent {currentSortArrow("inviteSent")}
                  </th>
                  <th
                    onClick={() => handleSort("currentSignIn")}
                    onKeyDown={() => handleSort("currentSignIn")}
                  >
                    Last Accessed
                  </th>
                  <th>
                    <PaginationWidget
                      currentPage={currentPage}
                      maxPage={maxPage}
                      showingAll={showingAll}
                    >
                      <div>
                        <p>{`${startIndex} - ${endIndex} of ${filteredClients.length}`}</p>
                      </div>
                      {filteredClients.length > maxPerPage && (
                        <div>
                          {!showingAll && (
                            <a
                              data-turbolinks={false}
                              onClick={() => handleShowAllClick()}
                            >
                              Show all
                            </a>
                          )}
                          {showingAll && (
                            <a onClick={() => handleRepaginateClick()}>
                              Back to page view
                            </a>
                          )}
                        </div>
                      )}
                      {filteredClients.length > maxPerPage && (
                        <div>
                          <i
                            onClick={() => prevPage()}
                            className="fas fa-chevron-left"
                          />
                          <i
                            onClick={() => nextPage()}
                            className="fas fa-chevron-right"
                          />
                        </div>
                      )}
                    </PaginationWidget>
                  </th>
                </IndexRow>

                {currentData().map(client => (
                  <ClientIndexRow client={client} key={client.id} />
                ))}
                <ClientForm setClients={setClients} setSortBy={setSortBy} />
              </tbody>
            </ClientTable>
          </>
        )}
      </main>
    </QueryClientProvider>
  );
}

ClientIndexPage.propTypes = {};
