/* eslint-disable react/destructuring-assignment */
import React from "react";
import Autocomplete from "react-autocomplete";
import PropTypes from "prop-types";
import { renderItem, menuStyles } from "./autocomplete_utils";
import CountryExplainerText from "./CountryExplainerText";

export default class AutocompleteField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id || "",
      value: props.value || "",
      items: props.items
    };
  }

  handleSelect(_, id) {
    const { value } = this.state.items.find(i => i.id === id);

    if (this.props.type === "object") {
      this.setState({ id, value });
    } else {
      this.setState({ id: value, value });
    }

    if (this.props.onParentChange) {
      this.props.onParentChange(id);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  renderMenu(_, items) {
    return <div style={menuStyles}>{items}</div>;
  }

  render() {
    return (
      <React.Fragment>
        <input type="hidden" name={this.props.name} value={this.state.id} />

        <Autocomplete
          items={this.state.items}
          shouldItemRender={(item, value) =>
            item &&
            item.value &&
            item.value.toLowerCase().indexOf((value || "").toLowerCase()) > -1
          }
          getItemValue={item => item.id}
          renderItem={(item, highlighted) =>
            renderItem(this, item, highlighted)
          }
          renderMenu={(items, query) => this.renderMenu(this, items, query)}
          value={this.state.value}
          onChange={e => this.setState({ value: e.target.value })}
          onSelect={id => this.handleSelect(this, id)}
          wrapperStyle={{ width: "100%" }}
          renderInput={props => (
            <React.Fragment>
              <input
                type="text"
                placeholder={this.props.placeholder}
                {...props}
              />
              <label>{this.props.label}</label>
            </React.Fragment>
          )}
        />
        {this.props.showCountryInfo && (
          <CountryExplainerText value={this.state.value} />
        )}
      </React.Fragment>
    );
  }
}

AutocompleteField.propTypes = {
  id: PropTypes.string, // object id to be filled in the hidden input if preselected
  value: PropTypes.string, // value to be filled in the hidden input if preselected
  name: PropTypes.string, // name assigned to the hidden input
  items: PropTypes.array, // collection of items for presentation
  type: PropTypes.string, // indicates it the collection of items are object or just string values
  placeholder: PropTypes.string, // placeholder text for the hidden input
  onParentChange: PropTypes.func // callback from parent that executes on value change
};
