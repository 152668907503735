import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  blue,
  blueDark,
  blueLight,
  blueLighter,
  blueLightest,
  gold,
  green,
  red,
  gray,
  grayDark,
  grayDarker,
  grayLight,
  grayLighter,
  grayLightest,
  white
} from "../../../styles/colors";

const Color = styled.div`
  color: ${props => (props.light ? white : grayDarker)};
  border-radius: 6px;
  display: inline-block;
  height: 12rem;
  margin: 0.5em;
  padding: 0.5em;
  width: 12rem;
  background-color: ${props => props.color};
`;

export default function ColorDocs() {
  return (
    <article id="color">
      <h1 className="thin">Color</h1>
      <Color color={blue} light>
        <p>$blue</p>
        <p>#2680FA</p>
      </Color>
      <Color color={blueDark} light>
        <p>$blue-dark</p>
        <p>#275BC0</p>
      </Color>
      <Color color={blueLight}>
        <p>$blue-light</p> <p>#80A6F2</p>
      </Color>
      <Color color={blueLighter}>
        <p>$blue-lighter</p> <p>#E9F2FE</p>
      </Color>
      <Color color={blueLightest}>
        <p>$blue-lightest</p> <p>#F5F9FF</p>
      </Color>
      <Color color={gold}>
        <p>$gold</p> <p>#F5D75B</p>
      </Color>
      <Color color={green}>
        <p>$green</p> <p>#41CD7E</p>
      </Color>
      <Color color={red}>
        <p>$red</p> <p>#ff5c5c</p>
      </Color>
      <Color color={gray}>
        <p>$gray</p> <p>#B9BFCB</p>
      </Color>
      <Color color={grayDark} light>
        <p>$gray-dark</p> <p>#818DA4</p>
      </Color>
      <Color color={grayDarker} light>
        <p>$gray-darker</p> <p>#3A4861</p>
      </Color>
      <Color color={grayLight}>
        <p>$gray-light</p> <p>#E9EDF0</p>
      </Color>
      <Color color={grayLighter}>
        <p>$gray-lighter</p> <p>#F1F5F9</p>
      </Color>
      <Color color={grayLightest}>
        <p>$gray-lightest</p> <p>#F8FAFC</p>
      </Color>
      <Color color={white} style={{ border: "1px solid gray" }}>
        <p>$white</p> <p>#FFF</p>
      </Color>
    </article>
  );
}

ColorDocs.propTypes = {};
