import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Provider as AlertProvider, positions, useAlert } from "react-alert";
import { LiveEditor, LiveProvider } from "react-live";
import github from "prism-react-renderer/themes/github";
import AlertTemplate from "../../Shared/AlertTemplate";

const options = {
  position: positions.TOP_RIGHT,
  timeout: 3500,
  offset: "30px"
};

function AlertTriggers() {
  const alert = useAlert();
  return (
    <div>
      <button
        onClick={() => alert.show("Test alert")}
        style={{ marginRight: "3rem" }}
      >
        Show alert
      </button>
      <button
        onClick={() => alert.show("Success Alert", { type: "success" })}
        style={{ marginRight: "3rem" }}
      >
        Show Success Alert
      </button>
      <button onClick={() => alert.show("Error Alert", { type: "error" })}>
        Show Error Alert
      </button>
    </div>
  );
}

const code = `
<div>
  <button
    onClick={() => alert.show("Test alert")}
    style={{ marginRight: "3rem" }}
  >
    Show alert
  </button>
  <button
    onClick={() => alert.show("Success Alert", { type: "success" })}
    style={{ marginRight: "3rem" }}
  >
    Show Success Alert
  </button>
  <button onClick={() => alert.show("Error Alert", { type: "error" })}>
    Show Error Alert
  </button>
</div>
`;

export default function AlertDocs(props) {
  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <h4>
        Alerts can be used to let them a user know that an action has failed or
        succeeded.
      </h4>
      <p>
        From anywhere in the component tree that is wrapped with{" "}
        <code>AlertProvider</code>, you can call <code>useAlert()</code>
      </p>
      <AlertTriggers />
      <LiveProvider
        scope={{ AlertTriggers }}
        code={code}
        theme={github}
        disabled
      >
        <LiveEditor
          style={{
            borderRadius: "4px",
            margin: "1rem 0",
            display: "grid"
          }}
        />
      </LiveProvider>
    </AlertProvider>
  );
}

AlertDocs.propTypes = {};
