import React, { useState } from "react";
import moment from "moment";
import { useAlert } from "react-alert";
import { IndexRow } from "../Shared/IndexRow/IndexRow";
import { green } from "../../styles/colors";
import { sendConfirmationEmail } from "../../services/emails";
import { useAuthContext } from "../Shared/ContextProviders/AuthProvider";

export default function ClientIndexRow({ client }) {
  const popup = useAlert();

  const { features } = useAuthContext();

  const {
    id,
    fullName,
    email,
    organizationNames,
    welcomeEmailSentAt,
    currentSignInAt
  } = client;

  const [welcomeEmailSentAtState, setWelcomeEmailSentAtState] = useState(
    welcomeEmailSentAt
  );

  const handleSendWelcomeEmail = async () => {
    const response = await sendConfirmationEmail(id, popup);
    setWelcomeEmailSentAtState(response.welcomeEmailSentAt);
  };

  return (
    <IndexRow key={id}>
      <td>
        <a
          href={Routes.client_path(id)}
          style={{
            color: "inherit",
            textDecoration: "underline dotted dodgerblue"
          }}
        >
          {fullName}
        </a>
      </td>
      <td>{email}</td>
      <td>{organizationNames.toString().replace(",", ", ")}</td>
      <td>
        {welcomeEmailSentAtState
          ? moment(welcomeEmailSentAtState).format("MM/DD/YY")
          : "-"}
      </td>
      <td>
        {currentSignInAt ? moment(currentSignInAt).format("MM/DD/YY") : "-"}
      </td>

      <td className="actions">
        {features.welcomeEmailButton && welcomeEmailSentAtState && (
          // Welcome email sent but user did NOT confirm
          <button
            type="button"
            className="button small secondary"
            onClick={() => handleSendWelcomeEmail()}
          >
            Resend welcome email
          </button>
        )}
        {features.welcomeEmailButton && !welcomeEmailSentAtState && (
          // Client never received the welcome email. Users who were "confirmed" by magic links
          // should still be able to get the welcome email.
          <button
            type="button"
            className="button small secondary"
            onClick={() => handleSendWelcomeEmail()}
          >
            Send welcome email
          </button>
        )}
      </td>
    </IndexRow>
  );
}

ClientIndexRow.propTypes = {};
