import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { LiveProvider, LiveEditor, LiveError, LivePreview } from "react-live";
import github from "prism-react-renderer/themes/github";
import Modal from "../../Shared/Modal/Modal";

const modalCode = `
  <>
  {isOpen && (
    <Modal show={isOpen} cleanup={() => setIsOpen(false)}>
      <h4>Add content here</h4>
      <p>Images, text, video, whatever!</p>
    </Modal>
  )}
  <button onClick={() => setIsOpen(true)}>Click me</button>
  </>
`;

export default function ModalDocs(props) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <h4>Modals</h4>
      <p>
        The modal component provides a great foundation for creating dialogs,
        popups, lightboxes, or whatever else.
      </p>
      <LiveProvider
        scope={{ Modal, isOpen, setIsOpen }}
        code={modalCode}
        theme={github}
      >
        <LivePreview />
        <LiveEditor
          style={{
            borderRadius: "4px",
            marginTop: "1rem",
            display: "grid"
          }}
        />
        <LiveError />
      </LiveProvider>
    </>
  );
}

ModalDocs.propTypes = {};
