import React from "react";
import { Provider as AlertProvider, positions } from "react-alert";
import AlertTemplate from "../Shared/AlertTemplate";
import ClientIndexPage from "./ClientIndexPage";
import { AuthContextProvider } from "../Shared/ContextProviders/AuthProvider";

const options = {
  position: positions.TOP_RIGHT,
  timeout: 3500,
  offset: "30px"
  // you can also just use 'scale'
};

export default function ClientIndexPageWrapper(props) {
  return (
    <AuthContextProvider>
      <AlertProvider template={AlertTemplate} {...options}>
        <ClientIndexPage {...props} />
      </AlertProvider>
    </AuthContextProvider>
  );
}
