import React, { useState } from "react";
import { useCookies } from "react-cookie";
import Modal from "../Shared/Modal/Modal";
import { green } from "../../styles/colors";

export default function SuccessModal() {
  const [isOpen, setIsOpen] = useState(true);

  const [, setCookie] = useCookies(["showSuccessModal"]);

  const handleIUnderstand = () => {
    setCookie("showSuccessModal", false);
    setIsOpen(false);
  };

  if (isOpen)
    return (
      <Modal show={isOpen}>
        <div>
          <div className="centered">
            <i
              className="fas fa-check-circle"
              style={{ color: green, fontSize: "4em" }}
            />
          </div>
          <div className="centered">
            <h4>You're done for now!</h4>
          </div>
          <p>
            Everything assigned to you has been submitted. You may revisit and
            edit the information at any time.
          </p>
        </div>
        <div className="right">
          <button
            type="button"
            className="text text-button no-padding"
            onClick={() => handleIUnderstand()}
          >
            Ok, I understand
          </button>
        </div>
      </Modal>
    );
  return null;
}

SuccessModal.propTypes = {};
