/* eslint-disable eqeqeq */
/* eslint-disable react/destructuring-assignment */
import React from "react";
import Select from "../../Shared/Select";
import AutocompleteField from "../../Autocomplete/AutocompleteField";
import DateField from "../../FormFields/DateField";
import NumberField from "../../FormFields/NumberField";
import PhoneField from "../../FormFields/PhoneField";
import SsnField from "../../FormFields/SsnField";
import RadioButtonGroup from "../../Shared/RadioButtonGroup";

export default function AttributeInput(props) {
  const { attribute } = props;
  const inputName = `entity_attributes[${attribute.name}]`;

  const renderRadio = () => (
    <RadioButtonGroup
      options={attribute.options}
      value={props.value}
      name={inputName}
      onChange={props.handleInputChange}
      labelClassName="input-button-label small inline"
    />
  );

  const renderSelect = () => (
    <Select
      options={attribute.options}
      value={props.value}
      name={inputName}
      placeholder={attribute.label}
      onSelect={props.handleInputChange}
    />
  );

  const renderTextArea = () => (
    <div className={`text-input-wrapper ${props.error ? "has-error" : ""}`}>
      <textarea
        type="text"
        name={inputName}
        placeholder={attribute.label}
        value={props.value}
        onChange={props.handleInputChange}
        onBlur={props.handleInputBlur}
      />
      <label>{attribute.label}</label>
      {props.error && <p>{props.error}</p>}
    </div>
  );

  const renderAutocomplete = () => (
    <div className={`text-input-wrapper ${props.error ? "has-error" : ""}`}>
      <AutocompleteField
        id={props.value}
        value={props.value}
        items={attribute.options}
        name={inputName}
        type="string"
        placeholder={attribute.label}
        label={attribute.label}
        onParentChange={props.handleInputChange}
      />
    </div>
  );

  const renderSSN = () => (
    <div className={`text-input-wrapper ${props.error ? "has-error" : ""}`}>
      <SsnField
        name={inputName}
        placeholder={attribute.label}
        value={props.value}
        label={attribute.label}
        handleInputChange={props.handleInputChange}
        handleInputBlur={props.handleInputBlur}
      />
      {props.error && <p>{props.error}</p>}
    </div>
  );

  const renderPhone = () => (
    <div className={`text-input-wrapper ${props.error ? "has-error" : ""}`}>
      <PhoneField
        name={inputName}
        placeholder={attribute.label}
        value={props.value}
        label={attribute.label}
        handleInputChange={props.handleInputChange}
        handleInputBlur={props.handleInputBlur}
      />
      {props.error && <p>{props.error}</p>}
    </div>
  );

  const renderUsd = () => (
    <div className={`text-input-wrapper ${props.error ? "has-error" : ""}`}>
      <NumberField
        name={inputName}
        placeholder={attribute.label}
        value={props.value}
        label={attribute.label}
        handleInputChange={props.handleInputChange}
        handleInputBlur={props.handleInputBlur}
        prefix="$"
        delimiter=","
        separator="."
        maxlength="24"
        precision="2"
        signed={false}
      />
      {props.error && <p>{props.error}</p>}
    </div>
  );

  const renderDate = () => (
    <div className={`text-input-wrapper ${props.error ? "has-error" : ""}`}>
      <DateField
        name={inputName}
        placeholder={attribute.label}
        value={props.value}
        label={attribute.label}
        handleInputChange={props.handleInputChange}
        handleInputBlur={props.handleInputBlur}
      />
      {props.error && <p>{props.error}</p>}
    </div>
  );

  const renderText = () => (
    <div className={`text-input-wrapper ${props.error ? "has-error" : ""}`}>
      <input
        type="text"
        name={inputName}
        placeholder={attribute.label}
        value={props.value}
        onChange={props.handleInputChange}
        onBlur={props.handleInputBlur}
        ref={props.inputRef}
      />
      <label>{attribute.label}</label>
      {props.error && <p>{props.error}</p>}
    </div>
  );

  if (attribute.inputType == "radio") {
    return renderRadio();
  }
  if (attribute.inputType == "select") {
    return renderSelect();
  }
  if (attribute.inputType == "textarea") {
    return renderTextArea();
  }
  if (attribute.inputType == "autocomplete") {
    return renderAutocomplete();
  }
  if (attribute.inputType == "ssn") {
    return renderSSN();
  }
  if (attribute.inputType == "phone") {
    return renderPhone();
  }
  if (attribute.inputType == "usd") {
    return renderUsd();
  }
  if (attribute.inputType == "date") {
    return renderDate();
  }

  return renderText();
}
