import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { LiveEditor, LiveError, LivePreview, LiveProvider } from "react-live";
import LiveCode from "../LiveCode";

const TextInputCode = `
      <>
        <div className="text-input-wrapper">
          <input placeholder="Text Input" type="text" />
          <label>Normal Input</label>
        </div>
        <div className="text-input-wrapper">
          <input placeholder="Email Input" type="email" />
          <label>Email Input</label>
        </div>
        <div className="text-input-wrapper">
          <input placeholder="Number Input" type="number" />
          <label>Number Input</label>
        </div>
        <div className="text-input-wrapper">
          <input placeholder="Date Input" type="date" />
          <label>Date Input</label>
        </div>
        <div className="text-input-wrapper">
          <textarea placeholder="Text Area" rows="4" />
          <label>Text Area</label>
        </div>
      </>
`;

const TextInputCode2 = `
  <>
    <h5>Text inputs can be small.</h5>
    <p>Add a class called small to the text-input-wrapper.</p>
    <div className="text-input-wrapper small">
      <input placeholder="Small Input" type="text" />
      <label>Small Input</label>
    </div>

    <h5>Small text inputs can be inline.</h5>
    <p>
      Add a class called inline to the text-input-wrapper. This cannot be
      applied to normal-sized inputs.
    </p>
    <div className="text-input-wrapper small inline">
      <input placeholder="Compensation" type="text" />
      <label>Compensation</label>
    </div>
    <div className="text-input-wrapper small inline">
      <input placeholder="Inline Input" type="text" />
      <label>Inline Input</label>
    </div>
  </>

`;

export default function TextInputDocs(props) {
  return (
    <>
      <h4>Text inputs allow users to input data</h4>
      <div>
        <LiveCode code={TextInputCode}>
          <p>Text inputs come in various types</p>
        </LiveCode>

        <LiveCode code={TextInputCode2}></LiveCode>

        <h5>Text inputs can show errors.</h5>
        <p>
          An input field can show the data contains errors by adding the
          .has-error class to .text-input-wrapper.
        </p>
        <div className="text-input-wrapper has-error">
          <input placeholder="Email" type="text" value="steve@ao" />
          <label>Email</label>
          <p>Please enter a valid email address.</p>
        </div>
      </div>
    </>
  );
}

TextInputDocs.propTypes = {};
