import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import Loader from "react-loader-spinner";
import { useAlert } from "react-alert";
import styled from "styled-components";
import request from "../../services/request";
import Dropdown from "../Shared/Dropdown/__Dropdown";
import PetitionIntakesBox from "../ClientDashboard/PetitionIntakesBox";

const Container = styled.div`
  &&& {
    padding: 0 !important;
  }
  display: grid;
  grid-gap: 1.5rem;
  grid-template-rows: auto auto;

  p {
    font-size: 18px;
  }
  small {
    font-size: 16px;
  }
`;

export default function ClientIntakes({ clientId }) {
  const popup = useAlert();
  const { isLoading, error, data: clientIntakeData } = useQuery(
    "dashboard",
    () => request(Routes.dashboard_petitions_path({ clientId }))
  );

  if (isLoading) {
    return <Loader type="ThreeDots" color="#2680FA" height={64} width={64} />;
  }

  if (error) {
    popup.show(`Something went wrong fetching your dashboards data: ${error}`, {
      type: "error"
    });
  }

  return (
    <Dropdown name="Intakes">
      {clientIntakeData?.petitions.map(petition => (
        <Container key={petition.id}>
          <PetitionIntakesBox
            header={`${petition.beneficiaryName} - ${petition.workflowName}`}
            intakes={petition.intakes}
            linkToPetition={Routes.petition_path(petition.id)}
            internal
          />
        </Container>
      ))}
    </Dropdown>
  );
}

ClientIntakes.propTypes = {
  clientId: PropTypes.string
};
