import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Select from "react-select";

export default function SelectDocs(props) {
  const testOptions = [
    {
      value: 123,
      label: "Green"
    },
    {
      value: 456,
      label: "Blue"
    },
    {
      value: 343,
      label: "Orange"
    }
  ];
  const [selectedValue, setSelectedValue] = useState(testOptions[0]);
  return (
    <>
      <h4>Selects allow users to select from predefined options.</h4>
      <p>
        We use a library called <code>react-select</code>, you can read about it{" "}
        <a href="https://react-select.com/home">here.</a>
      </p>
      <div style={{ width: "16rem" }}>
        <Select
          data-testid="select"
          value={selectedValue}
          options={testOptions}
          onChange={selectedValues => setSelectedValue(selectedValues)}
          placeholder="Select Color..."
        />
      </div>
    </>
  );
}

SelectDocs.propTypes = {};
