import React from "react";

export default class Autocomplete extends React.Component {
  componentDidMount() {
    const { input } = this;
    const { url, onChange } = this.props;
    $(input)
      .autocomplete({
        source: (request, response) => {
          this.fetchOptions(request, response, url);
        },
        change: (event, ui) => {
          event.preventDefault();
          if (ui.item === null) {
            onChange(null);
          } else {
            input.value = ui.item.label;
            onChange({
              id: ui.item.value,
              name: ui.item.label
            });
          }
        }
      })
      .autocomplete("instance")._renderItem = this.renderItem;
  }

  fetchOptions(request, response) {
    const { url, valueField, labelField } = this.props;
    $.ajax(url, {
      data: { q: request.term },
      success: data => {
        if (!data.items) {
          response([]);
          return;
        }
        const labels = new Map();
        const items = data.items.map(item => {
          const value = item[valueField];
          const label = item[labelField];

          const count = labels.has(label) ? labels.get(label) : 0;
          labels.set(label, count + 1);
          return {
            value,
            label
          };
        });
        items.forEach(item => {
          // TODO: This should happen server-side to prevent missed
          // ambiguity caused by the query result set size limit. However,
          // this is better than nothing.
          item.ambiguous = labels.get(item.label) > 1;
        });
        response(items);
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  renderItem(ul, item) {
    const $ui = $("<ui>");
    $ui.attr("data-value", item.value);
    const $div = $("<div>");
    $div.append($("<span>").text(item.label));
    if (item.ambiguous) {
      $div.append($("<br>"));
      $div.append($("<small>").text(item.value));
    }
    $ui.append($div);
    $ui.appendTo(ul);
    return $ui;
  }

  render() {
    const { placeholder, fieldName, defaultLabel } = this.props;
    return (
      <input
        className="autocomplete"
        name={fieldName}
        ref={el => {
          this.input = el;
        }}
        type="text"
        defaultValue={defaultLabel}
        placeholder={placeholder}
      />
    );
  }
}
