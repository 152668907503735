import React, { useState } from "react";
import { useAlert } from "react-alert";
import { useQuery } from "react-query";
import Loader from "react-loader-spinner";
import { Link, Redirect } from "react-router-dom";
import { BsQuestionCircle } from "react-icons/bs";
import styled from "styled-components";
import moment from "moment";
import request from "../../services/request";
import { IndexRow } from "../Shared/IndexRow/IndexRow";
import { IndexItem } from "../Shared/IndexItem/IndexItem";
import { blue, blueDark } from "../../styles/colors";
import { alphabeticalSort, chronologicalSort } from "../../utils/sorting";
import ProgressStepFlow from "./ProgressStepFlow";
import { ProgressDots } from "./ ProgressDots/ProgressDots";

const StyledContainer = styled.div`
  svg {
    font-size: 0.75rem;
    margin: 0.5rem 0.5rem 0.5rem 0;
    color: ${blueDark};
  }
`;

export function PetitionsPage() {
  const popup = useAlert();

  const {
    isLoading,
    error,
    data: dashboardData
  } = useQuery("dashboard-petitions", () =>
    request(Routes.dashboard_petitions_path())
  );

  const progressEnum = Object.freeze({
    "agreement signed": 0,
    "data gather": 1,
    "petition build": 2,
    "awaiting review": 3,
    "uscis decision": 4
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedStep, setSelectedStep] = useState(0);

  const handleStepClick = step => {
    setSelectedStep(step);
    setModalOpen(true);
  };

  const [sortBy, setSortBy] = useState({
    attribute: "updatedAt",
    ascending: true
  });

  const handleSort = attribute => {
    setSortBy(prevState => ({
      attribute,
      ascending: prevState.attribute === attribute ? !sortBy.ascending : true
    }));
  };

  const currentSortFunction = (a, b) => {
    switch (sortBy.attribute) {
      case "beneficiaryName":
        return alphabeticalSort(
          a.beneficiaryName,
          b.beneficiaryName,
          sortBy.ascending
        );
      case "visaType":
        return alphabeticalSort(
          a.workflowName,
          b.workflowName,
          sortBy.ascending
        );
      case "updatedAt":
        return chronologicalSort(a.updatedAt, b.updatedAt, sortBy.ascending);
      // By default petitions will be sorted by last updated
      default:
        return chronologicalSort(a.updatedAt, b.updatedAt, sortBy.ascending);
    }
  };

  const currentSortArrow = attribute => {
    if (sortBy.attribute === attribute && sortBy.ascending) return "↑";
    if (sortBy.attribute === attribute && !sortBy.ascending) return "↓";
  };

  const renderProgressText = progress => {
    // eslint-disable-next-line default-case
    switch (progress) {
      case 0:
        return "Agreement Signed";
      case 1:
        return "Data Gather";
      case 2:
        return "Build Petition";
      case 3:
        return "Awaiting Decision";
      case 4:
        return "USCIS Decision";
    }
  };

  if (isLoading) {
    return <Loader type="ThreeDots" color="#2680FA" height={64} width={64} />;
  }

  if (error) {
    popup.show(`Something went wrong fetching your dashboards data: ${error}`, {
      type: "error"
    });
  }

  if (!dashboardData.petitions.length) {
    return (
      <>
        <h3>Petitions</h3>
        <div
          className="centered"
          style={{ textAlign: "center", height: "50%" }}
        >
          <div>
            <h4>There are no petitions to show</h4>
            <p>
              Please contact{" "}
              <a href="mailto:customer-success@legalpad.io?subject=Question for Legalpad">
                customer-success@legalpad.io
              </a>{" "}
              for more information
            </p>
          </div>
        </div>
      </>
    );
  }

  if (dashboardData.petitions.length === 1) {
    return <Redirect to={`/petitions/${dashboardData.petitions[0].id}`} />;
  }

  return (
    <>
      <h3>Petitions</h3>
      <StyledContainer>
        <div style={{ display: "grid" }}>
          <IndexRow>
            <th onClick={() => handleSort("beneficiaryName")}>
              Petition {currentSortArrow("beneficiaryName")}
            </th>
            <th onClick={() => handleSort("workflowName")}>
              Visa type {currentSortArrow("workflowName")}
            </th>
            <th style={{ cursor: "default" }}>Status</th>
            <th onClick={() => handleSort("updatedAt")}>
              Last modified {currentSortArrow("updatedAt")}
            </th>
          </IndexRow>
        </div>
        {dashboardData.petitions
          .sort((a, b) => currentSortFunction(a, b))
          .map(petition => (
            <IndexRow
              key={petition.id}
              data-testid={petition.id}
              as={Link}
              to={`/petitions/${petition.id}`}
              style={{ color: "inherit", display: "grid" }}
            >
              <div>
                <IndexItem bold marginBottom>
                  {petition.beneficiaryName}
                </IndexItem>
                <IndexItem>{petition.organizationName}</IndexItem>
              </div>

              <IndexItem>{petition.workflowName}</IndexItem>
              <div>
                <IndexItem>
                  {renderProgressText(progressEnum[petition.progress])}{" "}
                  <BsQuestionCircle
                    style={{ color: blue, marginLeft: "0.5rem" }}
                    onClick={e => {
                      e.preventDefault();
                      handleStepClick(progressEnum[petition.progress]);
                    }}
                  />
                </IndexItem>
                <IndexItem>
                  <ProgressDots
                    progressEnum={progressEnum[petition.progress]}
                  />
                </IndexItem>
              </div>
              <IndexItem>{moment(petition.updatedAt).fromNow()}</IndexItem>
            </IndexRow>
          ))}
      </StyledContainer>
      {modalOpen && (
        <ProgressStepFlow
          currentStep={selectedStep}
          setModalOpen={setModalOpen}
        />
      )}
    </>
  );
}
