/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */
import React, { useState } from "react";
import UserMenu from "../Shared/UserMenu/UserMenu";
import UserAvatar from "../Shared/UserAvatar";
import { avatarInitials } from "../../utils/userHelpers";
import {
  AuthContextProvider,
  useAuthContext
} from "../Shared/ContextProviders/AuthProvider";

function Header() {
  const [showUserMenu, setShowUserMenu] = useState(false);

  const user = useAuthContext();

  return (
    <>
      <header className="fixed-header">
        <div className="left">
          <a href={Routes.root_path()}>
            <img
              className="app-header-logo"
              src={require("images/logo_dark.svg")}
              width={150}
              alt="Legalpad logo"
            />
          </a>
        </div>
        <div className="right">
          <div
            role="button"
            tabIndex={0}
            onClick={() => setShowUserMenu(!showUserMenu)}
            onKeyDown={() => setShowUserMenu(!showUserMenu)}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <UserAvatar size={42}>
              <span>{avatarInitials(user.firstName, user.lastName)}</span>
            </UserAvatar>
            <i
              style={{
                color: "dodgerblue",
                marginLeft: "1rem",
                cursor: "pointer"
              }}
              className={`fas ${
                showUserMenu ? "fa-chevron-up" : "fa-chevron-down"
              }`}
            ></i>
          </div>
          {showUserMenu && (
            <UserMenu
              user={user}
              avatarInitials={avatarInitials(user.firstName, user.lastName)}
            />
          )}
        </div>
      </header>
    </>
  );
}

export default function ClientNav() {
  return (
    <AuthContextProvider>
      <Header />
    </AuthContextProvider>
  );
}
