import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Switch,
  useRouteMatch
} from "react-router-dom";

import ReactMarkdown from "react-markdown";
import { Button } from "../Shared/Button/Button";
import { grayDark, grayDarkest, grayLight } from "../../styles/colors";
import DocsNav from "./DocsNav";
import TypographyDocs from "./DesignSystem/TypographyDocs";
import ColorDocs from "./DesignSystem/ColorDocs";
import ButtonDocs from "./Components/ButtonDocs";
import TextInputDocs from "./Components/TextInput";
import CheckboxDocs from "./Components/Checkbox";
import AutoSuggestDocs from "./Components/AutoSuggestDocs";
import LoaderDocs from "./Components/LoaderDocs";
import SelectDocs from "./Components/SelectDocs";
import ModalDocs from "./Components/ModalDocs";
import AlertDocs from "./Components/AlertDocs";

const StyleWrapper = styled.div`
  display: grid;
  grid-template-areas:
    "header header"
    "nav main";
  grid-template-columns: 1fr 4fr;
  grid-template-rows: 1fr 12fr;
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;

  main {
    overflow-y: auto;
    min-height: 100%;

    h4 {
      margin: 0 0 1rem 0;
    }
  }

  header {
    padding: 1rem;
    grid-area: header;
    border-bottom: 1px solid ${grayLight};
    h4 {
      margin: 0;
    }
  }

  nav {
    background: white;
    border-right: 1px solid ${grayLight};
    grid-area: nav;
    padding: 1.5rem 1rem;

    li {
      list-style: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      margin: 0 0 1.25rem 0;
      font-size: 1.2em;

      a {
        color: ${grayDarkest};
        &:visited {
          color: ${grayDarkest};
        }
      }
    }
  }

  main {
    padding: 1rem;
    grid-area: main;
    background: white;
  }
`;

const MarkdownContainer = styled.div`
  &&& {
    margin: 0 0 1rem 0;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
    }

    h1 {
      font-size: 28px;
    }
    h2 {
      font-size: 24px;
    }
    h3 {
      font-size: 21px;
    }

    ul {
      list-style: disc;
      padding-left: 2em;
    }

    li {
      margin-bottom: 0;
    }

    blockquote {
      margin-left: 1em;
      padding-left: 1em;
      border-left: 1px solid #ddd;
    }

    pre {
      background-color: #e1e7f0;
      border-radius: 4px;
      padding: 0.5rem;
    }
    code {
      font-family: "Consolas", "Menlo", "Monaco", monospace, serif;
      font-size: 0.9em;
    }
  }
`;

function ComponentRoutes(props) {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path="/components/button">
        <ButtonDocs />
      </Route>
      <Route exact path="/components/text-input">
        <TextInputDocs />
      </Route>
      <Route exact path="/components/auto-suggest">
        <AutoSuggestDocs />
      </Route>
      <Route exact path="/components/checkbox">
        <CheckboxDocs />
      </Route>
      <Route exact path="/components/loader">
        <LoaderDocs />
      </Route>
      <Route exact path="/components/select">
        <SelectDocs />
      </Route>
      <Route exact path="/components/modal">
        <ModalDocs />
      </Route>
      <Route exact path="/components/alert">
        <AlertDocs />
      </Route>
    </Switch>
  );
}

function DesignRoutes(props) {
  return (
    <Switch>
      <Route exact path="/design/typography">
        <TypographyDocs />
      </Route>
      <Route exact path="/design/color">
        <ColorDocs />
      </Route>
    </Switch>
  );
}

export default function DocsDashboard({ markdownFiles }) {
  return (
    <>
      <link
        rel="stylesheet"
        href="//cdnjs.cloudflare.com/ajax/libs/codemirror/5.0.0/codemirror.min.css"
      />
      <link
        rel="stylesheet"
        href="//cdnjs.cloudflare.com/ajax/libs/codemirror/5.0.0/theme/monokai.min.css"
      />
      <Router basename="/docs">
        <StyleWrapper>
          <header>
            <h4>
              Legalpad <span style={{ color: "dodgerblue" }}>Docs</span>
            </h4>
          </header>
          <DocsNav markdownFiles={markdownFiles} />
          <main>
            {markdownFiles.map(file => (
              <Route key={file.name} exact path={`/development/${file.name}`}>
                <MarkdownContainer>
                  <ReactMarkdown source={file.content} />
                </MarkdownContainer>
              </Route>
            ))}
            <DesignRoutes />
            <ComponentRoutes />
          </main>
        </StyleWrapper>
      </Router>
    </>
  );
}

DocsDashboard.propTypes = {};
