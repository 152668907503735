import React from "react";
import PropTypes from "prop-types";

export default function CountryExplainerText(props) {
  const { value } = props;

  const targetCountries = [
    "Mainland China",
    "India",
    "Philippines",
    "El Salvador",
    "Mexico",
    "Guatemala",
    "Honduras"
  ];

  if (targetCountries.includes(value)) {
    return (
      <section className="helper-text has-icon u_margin-top-3rem">
        <i className="fas fa-info-circle"> </i>
        <div className="content-wrapper">
          <p>
            Please note that your country of origin will determine when you can
            submit your I-485 (green card application). Wait times range from no
            wait to up to ten years.
          </p>
          <p>
            {" "}
            To check on your current wait time, please visit{" "}
            <a
              href="https://travel.state.gov/content/travel/en/legal/visa-law0/visa-bulletin/2020/visa-bulletin-for-october-2019.html"
              style={{ display: "inline" }}
            >
              the U.S. Department of State's Visa Bulletin
            </a>
          </p>
          <p>
            Find information about your visa type in the table under
            Employment-based Preferences Section A - Final Action Dates for
            Employment-based Preference Cases
          </p>
        </div>
      </section>
    );
  }
  return null;
}

CountryExplainerText.propTypes = {
  value: PropTypes.string // current input field value
};
