import { render, screen } from "@testing-library/react";
import { ProgressDots } from "./ProgressDots";

describe("The ProgressDots component", () => {
  it("renders without crashing", () => {
    render(<ProgressDots progressEnum={3} />);
  });

  it("displays correctly give the step enum 0", () => {
    render(<ProgressDots progressEnum={0} />);
    const dotArray = screen.queryAllByTestId("progress-dot");
    expect(dotArray.length).toEqual(5);
    expect(dotArray[0]).toHaveClass("full-circle");
    expect(dotArray[1]).toHaveClass("empty-circle");
    expect(dotArray[2]).toHaveClass("empty-circle");
    expect(dotArray[3]).toHaveClass("empty-circle");
    expect(dotArray[4]).toHaveClass("empty-circle");
  });

  it("displays correctly give the step enum 1", () => {
    render(<ProgressDots progressEnum={1} />);
    const dotArray = screen.queryAllByTestId("progress-dot");
    expect(dotArray.length).toEqual(5);
    expect(dotArray[0]).toHaveClass("full-circle");
    expect(dotArray[1]).toHaveClass("half-circle");
    expect(dotArray[2]).toHaveClass("empty-circle");
    expect(dotArray[3]).toHaveClass("empty-circle");
    expect(dotArray[4]).toHaveClass("empty-circle");
  });

  it("displays correctly give the step enum 2", () => {
    render(<ProgressDots progressEnum={2} />);
    const dotArray = screen.queryAllByTestId("progress-dot");
    expect(dotArray.length).toEqual(5);
    expect(dotArray[0]).toHaveClass("full-circle");
    expect(dotArray[1]).toHaveClass("full-circle");
    expect(dotArray[2]).toHaveClass("half-circle");
    expect(dotArray[3]).toHaveClass("empty-circle");
    expect(dotArray[4]).toHaveClass("empty-circle");
  });

  it("displays correctly give the step enum 3", () => {
    render(<ProgressDots progressEnum={3} />);
    const dotArray = screen.queryAllByTestId("progress-dot");
    expect(dotArray.length).toEqual(5);
    expect(dotArray[0]).toHaveClass("full-circle");
    expect(dotArray[1]).toHaveClass("full-circle");
    expect(dotArray[2]).toHaveClass("full-circle");
    expect(dotArray[3]).toHaveClass("half-circle");
    expect(dotArray[4]).toHaveClass("empty-circle");
  });

  it("displays correctly give the step enum 4", () => {
    render(<ProgressDots progressEnum={4} />);
    const dotArray = screen.queryAllByTestId("progress-dot");
    expect(dotArray.length).toEqual(5);
    dotArray.forEach(dot => {
      expect(dot).toHaveClass("full-circle");
    });
  });
});
