import React from "react";
import { LiveProvider, LiveEditor, LiveError, LivePreview } from "react-live";
import github from "prism-react-renderer/themes/github";
import { Button } from "../../Shared/Button/Button";

const ButtonColorCode = `
  <>
    <Button
      color="primary"
      size="medium"
      style={{ marginRight: "1rem" }}
    > Primary
    </Button>
    <Button
      color="secondary"
      size="medium"
      style={{ marginRight: "1rem" }}
    > Secondary
    </Button>
    <Button
      color="danger"
      size="medium"
      style={{ marginRight: "1rem" }}
    > Danger
    </Button>
  </>
`;

const ButtonSizeCode = `
  <>
    <Button
      color="secondary"
      size="small"
      style={{ marginRight: "1rem" }}
    > Small
    </Button>
    <Button
      color="secondary"
      size="medium"
      style={{ marginRight: "1rem" }}
    > Medium
    </Button>
    <Button
      color="secondary"
      size="large"
      style={{ marginRight: "1rem" }}
    > Large
    </Button>
  </>
`;

const ButtonTypeCode = `
  <>
    <Button variant="outlined" size="medium" style={{marginRight: "1rem"}}>
      Outlined
    </Button>
    <Button variant="text" size="medium" style={{marginRight: "1rem"}}>
      Text
    </Button>
    <Button size="medium" disabled style={{marginRight: "1rem"}}>Disabled</Button>
    <Button variant="text" size="medium" color="danger" >Text</Button>
  </>
`;

export default function ButtonDocs() {
  return (
    <>
      <h4>
        Buttons allow users to take actions, and make choices, with a single
        tap.
      </h4>
      <div>
        <LiveProvider scope={{ Button }} code={ButtonColorCode} theme={github}>
          <div>
            <p>Buttons come in colors</p>
            <LivePreview />
          </div>

          <LiveEditor
            style={{
              borderRadius: "4px",
              margin: "1rem 0",
              display: "grid"
            }}
          />
          <LiveError />
        </LiveProvider>
      </div>

      <div style={{ marginTop: "3rem" }}>
        <LiveProvider scope={{ Button }} code={ButtonSizeCode} theme={github}>
          <div>
            <p>Buttons can have different sizes</p>
            <LivePreview />
          </div>

          <LiveEditor
            style={{
              borderRadius: "4px",
              marginTop: "1rem",
              display: "grid"
            }}
          />
          <LiveError />
        </LiveProvider>
      </div>

      <div style={{ marginTop: "3rem" }}>
        <LiveProvider scope={{ Button }} code={ButtonTypeCode} theme={github}>
          <div>
            <p>
              Buttons come in different variants and can take on a disabled
              state
            </p>
            <LivePreview />
          </div>

          <LiveEditor
            style={{
              borderRadius: "4px",
              marginTop: "1rem",
              display: "grid"
            }}
          />
          <LiveError />
        </LiveProvider>
      </div>
    </>
  );
}

ButtonDocs.propTypes = {};
