/* eslint-disable react/destructuring-assignment */
import React, { useState, useCallback, useRef } from "react";
import AttributeDisplay from "./AttributeDisplay";
import AttributeForm from "./AttributeForm/AttributeForm";
import AutocompleteForm from "../../Autocomplete/AutocompleteForm";

export default function AttributeItem(props) {
  const { attribute } = props;
  const showFormInitialValue =
    !props.attribute.value &&
    props.attribute.inputType !== "radio" &&
    !props.readonly;
  const [value, setValue] = useState(attribute.value || "");
  const [showForm, setShowForm] = useState(showFormInitialValue);
  const [showModal, setShowModal] = useState(false);

  const clicked = useRef(false);

  const inputEl = useCallback(
    el => {
      // eslint-disable-next-line no-unused-expressions
      clicked.current === true && el.focus();
      clicked.current = false;
    },
    [clicked]
  );

  const handleValueClick = e => {
    e.preventDefault();
    setShowForm(true);
    clicked.current = true;
  };

  const renderModal = () => (
    <div className="value small">
      This variable is part of this Accelerator's template. To change it please
      go to this accelerator's page.
    </div>
  );

  const renderForm = () => {
    if (attribute.inputType === "object_autocomplete") {
      return (
        <AutocompleteForm
          id={props.id}
          type={props.type}
          value={value}
          attribute={attribute}
          toggleForm={bool => setShowForm(bool)}
          setValue={newValue => setValue(newValue)}
          onChange={props.onChange}
        />
      );
    }
    return (
      <AttributeForm
        id={props.id}
        type={props.type}
        attribute={attribute}
        value={value}
        toggleForm={bool => setShowForm(bool)}
        setValue={newValue => setValue(newValue)}
        inputRef={inputEl}
        noSubmit={props.noSubmit}
      />
    );
  };

  const renderDisplay = () => {
    if (props.readonly) {
      return (
        <AttributeDisplay
          value={value}
          attribute={attribute}
          onClick={e => e.preventDefault()}
          onMouseEnter={() => setShowModal(true)}
          onMouseLeave={() => setShowModal(false)}
        />
      );
    }

    return (
      <AttributeDisplay
        value={value}
        attribute={attribute}
        onClick={e => handleValueClick(e)}
      />
    );
  };

  return (
    <div className="attribute-item attribute">
      {!showForm && <h6 className="label">{attribute.label}</h6>}
      {showForm ? renderForm() : renderDisplay()}
      {showModal && renderModal()}
    </div>
  );
}
