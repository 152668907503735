import React from "react";
import PropTypes from "prop-types";
import { LiveEditor, LiveError, LivePreview, LiveProvider } from "react-live";
import github from "prism-react-renderer/themes/github";

export default function LiveCode({ scope, code, children }) {
  return (
    <LiveProvider scope={scope} code={code} theme={github}>
      <div>
        {children}
        <LivePreview />
      </div>

      <LiveEditor
        style={{
          borderRadius: "4px",
          margin: "1rem 0",
          display: "grid"
        }}
      />
      <LiveError />
    </LiveProvider>
  );
}

LiveCode.propTypes = {
  code: PropTypes.string
};
