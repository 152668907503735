import React, { useEffect } from "react";
import { Provider as AlertProvider, positions } from "react-alert";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import styled from "styled-components";
import { QueryClient, QueryClientProvider } from "react-query";
import { CookiesProvider, useCookies } from "react-cookie";
import { blue, gray, grayLight } from "../../styles/colors";
import AlertTemplate from "../Shared/AlertTemplate";
import {
  AuthContextProvider,
  useAuthContext
} from "../Shared/ContextProviders/AuthProvider";
import { DashboardContextProvider } from "../Shared/ContextProviders/DashboardProvider";
import ClientNav from "./ClientNav";
import WelcomeFlow from "./WelcomeFlow";
import { PetitionsPage } from "./PetitionsPage";
import { PetitionDetail } from "./PetitionDetail";

const queryClient = new QueryClient();

const options = {
  position: positions.TOP_RIGHT,
  timeout: 3500,
  offset: "30px"
};

const StyledMain = styled.main`
  width: 100vw !important;

  @media only screen and (min-width: 600px) {
    padding: 2.5rem;
  }

  padding-top: 6rem !important;

  h1 {
    width: fit-content;
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 0.75rem;
  }

  p {
    margin-bottom: 1rem;
  }

  button {
    padding: 1rem;
    min-width: 8rem;
    &.secondary {
      border: 1px solid ${gray};
      color: ${blue};
      background: #fff;
      &:hover {
        background: ${grayLight};
      }
    }
  }
`;

function Routes() {
  const { showWelcomeFlow } = useAuthContext();

  const [cookies, setCookie] = useCookies(["showWelcomeFlow"]);

  useEffect(() => {
    // first, use the value from the server to set the cookie if its null
    // if the cookie exists, use that value to determine whether or not to display
    if (showWelcomeFlow && cookies.showWelcomeFlow == null) {
      setCookie("showWelcomeFlow", true);
    }
  }, []);

  return (
    <Switch>
      {cookies.showWelcomeFlow === "true" && <WelcomeFlow />}
      <Route exact path="/petitions">
        <PetitionsPage />
      </Route>
      <Route path="/petitions/:id">
        <PetitionDetail />
      </Route>
      <Redirect to="/petitions" />
    </Switch>
  );
}

export default function ClientDashboard() {
  return (
    <QueryClientProvider client={queryClient}>
      <CookiesProvider>
        <AlertProvider template={AlertTemplate} {...options}>
          <AuthContextProvider>
            <DashboardContextProvider>
              <Router basename="/dashboard">
                <ClientNav />
                <StyledMain>
                  <Routes />
                </StyledMain>
              </Router>
            </DashboardContextProvider>
          </AuthContextProvider>
        </AlertProvider>
      </CookiesProvider>
    </QueryClientProvider>
  );
}
