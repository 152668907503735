import React from "react";
import { useAlert } from "react-alert";
import Loader from "react-loader-spinner";
import { useQuery } from "react-query";
import { useParams, Link } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs";
import styled from "styled-components";
import request from "../../services/request";
import PetitionIntakesBox from "./PetitionIntakesBox";
import PetitionProgressBar from "./PetitionProgressBar";
import Dropdown from "../Shared/Dropdown/Dropdown";
import { gray, grayLight } from "../../styles/colors";

const ContactBox = styled.div`
  background: white;
  border-radius: 4px;
  border: 1px solid ${grayLight};
  padding: 0.75rem;
  display: grid;
  margin: 0 0 1rem 0;

  .full-name {
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 0.35rem 0;
  }

  .title {
    color: ${gray};
    margin: 0 0 0.35rem 0;
  }

  a {
    margin: 0;
  }
`;

export function PetitionDetail() {
  const popup = useAlert();

  const { id: petitionId } = useParams();

  const { isLoading, error, data: petitionData } = useQuery(
    ["dashboard-petition", petitionId],
    () =>
      request(`/dashboard_petitions/${petitionId}`, {
        enabled: !!petitionId
      })
  );

  // needed to conditionally show back button to petitions
  const { data: dashboardData } = useQuery("dashboard-petitions", () =>
    request(Routes.dashboard_petitions_path())
  );

  if (isLoading) {
    return <Loader type="ThreeDots" color="#2680FA" height={64} width={64} />;
  }

  if (error) {
    popup.show(`Something went wrong fetching your petitions data: ${error}`, {
      type: "error"
    });
  }

  return (
    <>
      {dashboardData?.petitions.length > 1 && (
        <Link
          to="/petitions"
          style={{
            alignItems: "center",
            justifyContent: "left",
            display: "flex"
          }}
        >
          <BsArrowLeftShort /> Petitions
        </Link>
      )}
      <h3 style={{ margin: "1rem 0 1.5rem 0" }}>
        {petitionData.beneficiaryName}, {petitionData.workflowName}
      </h3>
      <PetitionProgressBar progress={petitionData.progress} />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "3fr 1fr",
          gridGap: "1.5rem"
        }}
      >
        <section data-testId="intakes-section">
          <Dropdown name="Intakes">
            {petitionData.intakes.length > 0 && (
              <PetitionIntakesBox noHeader intakes={petitionData.intakes} />
            )}
            {petitionData.intakes.length === 0 && (
              <p>
                There are no intakes to show for this petition at the moment.
                Please contact your Immigration Specialist if you have
                questions.
              </p>
            )}
          </Dropdown>
        </section>
        <section data-testId="contacts-section">
          <Dropdown name="Contacts">
            {petitionData.contacts.map(contact => (
              contact.title !== 'Blank' &&
              <ContactBox>
                <strong className="full-name">{contact.fullName}</strong>
                <p className="title">{contact.title}</p>
                <a href={`mailto:${contact.email}`}>{contact.email}</a>
              </ContactBox>
            ))}
          </Dropdown>
        </section>
      </div>
    </>
  );
}
