/* eslint-disable react/destructuring-assignment */
import React from "react";
import Form from "../../FormFields/Form";
import AttributeRow from "./AttributeRow";

export default class Entity extends React.Component {
  // See ClientInformation for documentation

  constructor(props) {
    super(props);
    this.entity = this.props.entity;
    this.form = React.createRef();
  }

  _handleSubmit(e) {
    e.preventDefault();

    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm(
      "Are you sure? This is super permanent and cannot be undone."
    );

    if (confirmed) {
      const { id } = this.entity;
      const url = Routes.entity_path(id);
      const data = new FormData(e.target);

      fetch(url, { method: "DELETE", body: data }).then(response => {
        if (response.ok) {
          this.props.removeEntityFromState(id);
        } else {
          alert("Unable to remove this record, please notify engineering.");
        }
      });
    }
  }

  _renderAttributeRow(attribute) {
    return (
      <AttributeRow
        type={this.entity.type}
        subtype={this.entity.subtype}
        id={this.entity.id}
        attribute={attribute}
        key={attribute.label}
      />
    );
  }

  _secondaryHeader() {
    return (
      <React.Fragment>
        <h4>{`${this.entity.name} ${this.props.index + 1}`}</h4>
        <Form onSubmit={e => this._handleSubmit(e)} method="DELETE">
          <input type="hidden" name="entity_id" value={this.entity.id} />
          <input type="hidden" name="entity_type" value={this.entity.type} />
          <button className="text-button no-padding destructive" type="submit">
            Remove
          </button>
        </Form>
      </React.Fragment>
    );
  }

  render() {
    let filteredAttributes = this.entity.attributes;

    if (!this.entity.source) {
      // Set default filter to hide operator variables
      if (!this.props.filterState.source) {
        filteredAttributes = this.entity.attributes.filter(
          attribute => attribute.source !== "Operator"
        );
      }

      // Filter based on source first
      if (this.props.filterState.source) {
        filteredAttributes = this.entity.attributes.filter(
          attribute => this.props.filterState.source === attribute.source
        );
      }

      // Then filter based on sections array if not empty
      if (this.props.filterState.sections.length > 0) {
        filteredAttributes = filteredAttributes.filter(attribute =>
          this.props.filterState.sections.includes(attribute.section)
        );
      }

      // Lastly handle our search filter
      if (this.props.filterState.searchString) {
        filteredAttributes = filteredAttributes.filter(attribute =>
          attribute.name
            .toLowerCase()
            .includes(this.props.filterState.searchString.trim().toLowerCase())
        );
      }
    }

    return (
      filteredAttributes.length > 0 && (
        <section id={this.entity.name}>
          {this.props.secondary ? (
            this._secondaryHeader()
          ) : (
            <h3>{this.entity.name}</h3>
          )}
          {filteredAttributes.map(attribute =>
            this._renderAttributeRow(attribute)
          )}
        </section>
      )
    );
  }
}
