import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { gold, grayDark, green } from "../../styles/colors";

const Container = styled.div`
  padding: 0.5rem 0.75rem;
  border: 2px solid ${props => props.borderColor};
  border-radius: 4px;
  background: white;
`;

export default function Notice({ message, type }) {
  return (
    <Container
      borderColor={
        type === "success" ? green : type === "warning" ? gold : grayDark
      }
    >
      {message}
    </Container>
  );
}

Notice.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string
};
