/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-destructuring */
import React from "react";
import Form from "../FormFields/Form";
import OrganizationAutocompleteField from "./OrganizationAutocompleteField";
import PersonAutocompleteField from "./PersonAutocompleteField";
import EntityFields from "../FormFields/EntityFields";
import ActionBar from "../FormFields/ActionBar";

function findOptionById(id, options) {
  return options.find(o => o.id === id);
}

export default class AutocompleteForm extends React.Component {
  // See ClientInformation for documentation

  constructor(props) {
    super(props);
    this.attribute = props.attribute;
    this.state = {
      showActions: false,
      id: this.attribute.id,
      value: props.value,
      persistedValue: props.value,
      error: ""
    };
    this.inputName = `entity_attributes[${this.attribute.columnName}]`;
    this.writeInNamePrefix = `entity_attributes[${this.attribute.name}_attributes]`;
  }

  _handleSubmit(e) {
    e.preventDefault();
    const context = this;
    const url = Routes.entity_path(this.props.id);
    const data = new FormData(e.target);
    let value;
    if (this.state.id.length > 0) {
      value = findOptionById(this.state.id, this.attribute.options).value;
    } else {
      value = this.state.value;
    }

    fetch(url, { method: "PUT", body: data }).then(response => {
      if (response.ok) {
        this.props.toggleForm(!value);
        this.props.setValue(value);
        if (this.props.onChange) {
          response.json().then(d => {
            this.props.onChange(d);
          });
        }
      } else {
        response.json().then(error => {
          context.setState({ error });
        });
      }
    });
  }

  _handleInputChange(id) {
    const { value } = findOptionById(id, this.attribute.options);

    this.props.setValue(value);
    this.setState({
      id,
      value,
      showActions: true
    });
  }

  _handleNewItemClick(writeInValue) {
    this.setState({
      showActions: true,
      value: writeInValue,
      id: ""
    });
    this.props.setValue(writeInValue);
  }

  _handleCancel() {
    this.props.setValue(this.state.persistedValue);
    this.props.toggleForm(false);
    this.setState({ showActions: false });
  }

  _renderAutocomplete() {
    if (this.attribute.type === "Person" || this.attribute.type === "Contact") {
      return (
        <PersonAutocompleteField
          id={this.state.id}
          value={this.state.value}
          name={this.inputName}
          personFieldsPrefix={this.writeInNamePrefix}
          items={this.attribute.options}
          placeholder={this.attribute.label}
          label={this.attribute.label}
          attributeType={this.attribute.type}
          onParentChange={e => this._handleInputChange(e)}
          onNewItemClick={e => this._handleNewItemClick(e)}
          onPersonFieldsChange={value => this.setState({ value })}
        />
      );
    }
    let writeInName;
    if (this.attribute.type === "Organization") {
      writeInName = `${this.writeInNamePrefix}[display_name]`;
    } else if (this.attribute.type === "Membership") {
      writeInName = `${this.writeInNamePrefix}[name]`;
    }
    return (
      <OrganizationAutocompleteField
        id={this.state.id}
        value={this.state.value}
        name={this.inputName}
        writeInName={writeInName}
        items={this.attribute.options}
        placeholder={this.attribute.label}
        label={this.attribute.label}
        onParentChange={e => this._handleInputChange(e)}
        onNewItemClick={e => this._handleNewItemClick(e)}
        onWriteInFieldChange={value => this.setState({ value })}
      />
    );
  }

  render() {
    return (
      <React.Fragment>
        <Form onSubmit={e => this._handleSubmit(e)}>
          <EntityFields type={this.props.type} id={this.props.id} />
          <div
            className={`text-input-wrapper ${
              this.state.error ? "has-error" : ""
            }`}
          >
            {this._renderAutocomplete()}
            {this.state.error && <p>{this.state.error}</p>}
          </div>
          {this.state.showActions && (
            <ActionBar handleCancel={() => this._handleCancel()} />
          )}
        </Form>
      </React.Fragment>
    );
  }
}
