/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import PropTypes from "prop-types";
import { readableValue } from "../../../utils/value_formatting";

export default function AttributeDisplay(props) {
  const { onMouseEnter, onMouseLeave, onClick, value, attribute } = props;

  const renderExternalLink = href => (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="external-link"
    ></a>
  );

  return (
    <React.Fragment>
      <h5
        className="value"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
      >
        <a href="">{readableValue(value, attribute.inputType)}</a>
      </h5>
      {attribute.inputType === "url" && renderExternalLink(value)}
    </React.Fragment>
  );
}

AttributeDisplay.propTypes = {
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClick: PropTypes.func,
  value: PropTypes.string,
  attribute: PropTypes.object
};
