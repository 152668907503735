/* eslint-disable react/destructuring-assignment */
import React from "react";
import AttributeDisplay from "./AttributeDisplay";
import ContactForm from "./ContactForm";

const setAttributesHash = attributes => {
  if (attributes) {
    const attributesHash = {};
    // eslint-disable-next-line array-callback-return
    attributes.map(attribute => {
      attributesHash[attribute.name] = attribute.value;
    });
    return attributesHash;
  }
};

const buildName = attributes => {
  const firstNameAttributes = attributes.filter(
    attribute => attribute.name === "first_name"
  );
  const lastNameAttributes = attributes.filter(
    attribute => attribute.name === "last_name"
  );
  if (firstNameAttributes.length > 0 && lastNameAttributes.length > 0) {
    return `${firstNameAttributes[0].value} ${lastNameAttributes[0].value}`;
  }
  return "Contact name";
};

export default class ContactItem extends React.Component {
  constructor(props) {
    super(props);
    this.attribute = props.attribute;
    this.state = {
      showForm: !props.attribute.value,
      attributesHash: setAttributesHash(this.attribute.attributes),
      value: buildName(this.attribute.attributes)
    };
  }

  _handleCancel() {
    this.setState({ showForm: false });
  }

  _handleSubmit(data) {
    const contactData = this._parseContactData(data);
    this.setState({
      attributesHash: setAttributesHash(contactData),
      showForm: false,
      value: buildName(contactData)
    });
  }

  _handleValueClick(e) {
    e.preventDefault();
    this.setState({ showForm: true });
  }

  _parseContactData(responseAttributes) {
    const matchingContactObjects = this._matchingContactData(
      responseAttributes
    );
    return matchingContactObjects.length > 0
      ? matchingContactObjects[0].attributes
      : [];
  }

  // When you create a new contact record, we won't have an id to match on.
  // Try to filter based on this.attribute.id if present, otherwise find the first attribute with type 'contact'
  _matchingContactData(responseAttributes) {
    if (this.attribute.id) {
      return responseAttributes.filter(
        attribute =>
          (attribute.id =
            this.attribute.id && attribute.inputType === "contact")
      );
    }
    return responseAttributes.filter(
      attribute => attribute.inputType === "contact"
    );
  }

  _renderForm() {
    return (
      <ContactForm
        id={this.props.id}
        type={this.props.type}
        value={this.state.value}
        attribute={this.attribute}
        attributesHash={this.state.attributesHash}
        handleCancel={() => this._handleCancel()}
        handleSubmit={hash => this._handleSubmit(hash)}
      />
    );
  }

  _renderDisplay() {
    return (
      <AttributeDisplay
        value={this.state.value}
        attribute={this.attribute}
        attributesHash={this.state.attributesHash}
        onClick={e => this._handleValueClick(e)}
      />
    );
  }

  render() {
    return (
      <div className="attribute-item attribute">
        <h6 className="label">{this.attribute.label}</h6>
        {this.state.showForm ? this._renderForm() : this._renderDisplay()}
      </div>
    );
  }
}
