import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { blue } from "../../../../styles/colors";

const SearchBar = styled.input`
  margin: 1rem 2rem 1rem 1rem;
  padding: 0.5rem;
  border-radius: 4px;
  width: auto;
  width: -moz-available;
  border: none;
  :focus {
    box-shadow: 1px 1px 5px ${blue};
  }
`;

export default function ClientInfoSearch(props) {
  const { dispatchFilter } = props;

  const [inputValue, setInputValue] = useState("");

  const handleChange = e => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    // Debounce the input handler that calls the dispatchFilter
    const debounceIt = setTimeout(() => {
      dispatchFilter({ type: "SET_SEARCH_STRING", searchString: inputValue });
    }, 300);
    return () => clearTimeout(debounceIt);
  }, [inputValue]);

  return (
    <SearchBar
      type="text"
      value={inputValue}
      onChange={e => handleChange(e)}
      placeholder="Search by attribute..."
    />
  );
}

ClientInfoSearch.propTypes = {
  dispatchFilter: PropTypes.func
};
