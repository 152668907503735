/* eslint-disable react/destructuring-assignment */
import React from "react";
import Form from "../../FormFields/Form";
import AttributeRow from "./AttributeRow";

export default class MembershipEntity extends React.Component {
  // See ClientInformation for documentation

  constructor(props) {
    super(props);
    this.entity = this.props.entity;
    this.form = React.createRef();
    this.state = {
      membershipAttributes: [],
      membershipBody: this.entity.attributes.find(
        a => a.name === "membership_body"
      )
    };
  }

  componentDidMount() {
    if (this.state.membershipBody.id) {
      this._fetchAttributes(this.state.membershipBody.id);
    }
  }

  _fetchAttributes(id) {
    const attributes = [];
    fetch(
      Routes.entity_attributes_path({
        entity_id: id,
        entity_type: this.entity.subtype
      })
    ).then(response => {
      if (response.ok) {
        response.json().then(data => {
          this._setMembershipAttributes(data);
        });
      } else {
        response.json().then(errors => {
          alert("Couldn't pull attributes");
        });
      }
    });
    return attributes;
  }

  _setMembershipAttributes(attributes) {
    this.setState({
      membershipAttributes: attributes
    });
  }

  _handleSubmit(e) {
    e.preventDefault();

    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm(
      "Are you sure? This is super permanent and cannot be undone."
    );

    if (confirmed) {
      const { id } = this.entity;
      const url = Routes.entity_path(id);
      const data = new FormData(e.target);

      fetch(url, { method: "DELETE", body: data }).then(response => {
        if (response.ok) {
          this.props.removeEntityFromState(id);
        } else {
          alert("Unable to remove this record, please notify engineering.");
        }
      });
    }
  }

  _onMembershipBodyChange(attributes) {
    const membershipBody = attributes.find(a => a.name === "membership_body");
    if (membershipBody) {
      this._fetchAttributes(membershipBody.id);
      this.setState({ membershipBody });
    }
  }

  _renderAttributeRow(attribute, type, entityId) {
    const hideRow = !!this.props.inputFilter && attribute.source === "Operator";

    if (!hideRow) {
      let isReadonly;
      if (type === "Accelerator") {
        isReadonly =
          this.state.membershipAttributes.find(attr => attr.name === "featured")
            .value === "true";
      } else if (attribute.inputType === "contact") {
        isReadonly = true;
      }
      return (
        <AttributeRow
          type={type}
          id={entityId}
          attribute={attribute}
          readonly={isReadonly}
          key={attribute.label}
          onChange={value => this._onMembershipBodyChange(value)}
        />
      );
    }
  }

  _secondaryHeader() {
    return (
      <React.Fragment>
        <h4>{`${this.entity.name} ${this.props.index + 1}`}</h4>
        <Form onSubmit={e => this._handleSubmit(e)} method="DELETE">
          <input type="hidden" name="entity_id" value={this.entity.id} />
          <input type="hidden" name="entity_type" value={this.entity.type} />
          <button className="text-button no-padding destructive" type="submit">
            Remove
          </button>
        </Form>
      </React.Fragment>
    );
  }

  render() {
    return (
      <section id={this.entity.name}>
        {this.props.secondary ? (
          this._secondaryHeader()
        ) : (
          <h3>{this.entity.name}</h3>
        )}
        {this.entity.attributes.map(attribute =>
          this._renderAttributeRow(attribute, this.entity.type, this.entity.id)
        )}
        {this.state.membershipAttributes.map(attribute =>
          this._renderAttributeRow(
            attribute,
            this.entity.subtype,
            this.state.membershipBody.id
          )
        )}
      </section>
    );
  }
}
