import React, { useState } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { blue, blueDark, grayLight, green, white } from "../../styles/colors";
import ProgressStepFlow from "./ProgressStepFlow";

const Container = styled.div`
  text-align: center;
  display: inline-block;
  overflow: hidden;
  border-radius: 4px;
  counter-reset: flag ${props => props.counterStart};
`;

const Step = styled.a`
  &&& {
    text-decoration: none;
    outline: none;
    display: block;
    float: left;
    font-size: 14px;
    line-height: 36px;
    padding: 0 10px 0 60px;
    position: relative;
    background: ${white};
    color: ${blue};
    transition: background 0.5s;

    &:first-child {
      padding-left: 46px;
      border-radius: 5px 0 0 5px;
    }

    &:first-child::before {
      left: 14px;
    }

    &:last-child::after {
      content: none;
    }

    ::before {
      ${props =>
        props.complete
          ? css`
              content: "✓";
              color: ${white} !important;
              box-shadow: 0 0 0 1px ${green};
              background: ${green};
            `
          : css`
              content: counter(flag);
              counter-increment: flag;
              box-shadow: 0 0 0 1px ${blue};
              background: ${white};
            `}

      border-radius: 100%;
      width: 20px;
      height: 20px;
      line-height: 20px;
      margin: 8px 0;
      position: absolute;
      top: 0;
      left: 30px;
      font-weight: bold;
    }

    ::after {
      content: "";
      position: absolute;
      top: 0;
      right: -18px;
      width: 36px;
      height: 36px;
      transform: scale(0.707) rotate(45deg);
      z-index: 1;
      border-radius: 0 5px 0 50px;
      background: ${white};
      transition: background 0.5s;
      box-shadow: 2px -2px 0 2px ${grayLight};
    }

    &:hover {
      color: ${white};
      background: ${blueDark};
    }

    &:hover::before {
      color: ${blue};
    }

    &:hover::after {
      color: ${blue};
      background: ${blueDark};
    }

    ${props =>
      props.active
        ? css`
             {
              color: ${white};
              background: ${blueDark};

              ::before {
                color: ${blueDark};
              }

              ::after {
                background: ${blueDark};
              }
            }
          `
        : ""}
  }
`;

export default function PetitionProgressBar({ progress }) {
  const progressEnum = Object.freeze({
    "agreement signed": 0,
    "data gather": 1,
    "petition build": 2,
    "awaiting review": 3,
    "uscis decision": 4
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedStep, setSelectedStep] = useState(progressEnum[progress]);

  const handleStepClick = step => {
    setSelectedStep(step);
    setModalOpen(true);
  };

  return (
    <>
      <Container counterStart={progressEnum[progress]}>
        <Step
          onClick={() => handleStepClick(0)}
          complete={progressEnum[progress] > 0}
          active={progressEnum[progress] >= 0}
        >
          Sign Agreement
        </Step>
        <Step
          onClick={() => handleStepClick(1)}
          complete={progressEnum[progress] > 1}
          active={progressEnum[progress] > 0}
        >
          Data Gather
        </Step>
        <Step
          onClick={() => handleStepClick(2)}
          complete={progressEnum[progress] > 2}
          active={progressEnum[progress] > 1}
        >
          Build Petition
        </Step>
        <Step
          onClick={() => handleStepClick(3)}
          complete={progressEnum[progress] > 3}
          active={progressEnum[progress] > 2}
        >
          Awaiting Decision
        </Step>
        <Step
          onClick={() => handleStepClick(4)}
          complete={progressEnum[progress] > 4}
          active={progressEnum[progress] > 3}
        >
          USCIS Decision
        </Step>
      </Container>
      {modalOpen && (
        <ProgressStepFlow
          currentStep={selectedStep}
          setModalOpen={setModalOpen}
        />
      )}
    </>
  );
}

PetitionProgressBar.propTypes = {
  progress: PropTypes.string
};
